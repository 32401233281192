import Vue from 'vue'
import VueShepherd from 'vue-shepherd'
import $ from 'jquery'

Vue.use(VueShepherd)

export default class Tour extends Vue {
  defaultOptions = {
    useModalOverlay: true,
    scrollTo: true,
    cancelIcon: {
      enabled: true
    }
  }

  tour = null
  currentStep = 0

  constructor (membershipType, stepTexts, defaultOptions) {
    super()
    if (defaultOptions != null) this.defaultOptions = defaultOptions
    this.tour = this.$shepherd(this.defaultOptions)
    this.setDefaultSteps(membershipType, stepTexts)
  }

  setDefaultSteps (membershipType, stepTexts) {
    const _this = this
    _this.tour.addStep({
      id: 'tour-menue-infopaket',
      text: stepTexts.step1,
      attachTo: {
        element: '.mb-left-nav-cont .mb-nav-infopaket',
        on: 'right'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 90, '.mb-left-nav-cont')
      },
      scrollTo: true,
      cancelIcon: {
        enabled: true
      }
    })
    _this.tour.addStep({
      id: 'tour-infopaket',
      text: stepTexts.step2,
      attachTo: {
        element: '.mb-route-infopaket .mb-main-content',
        on: 'top'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: false
    })
    _this.tour.addStep({
      id: 'tour-menue-kontakte',
      text: stepTexts.step3,
      attachTo: {
        element: '.mb-left-nav-cont .mb-nav-kontakte',
        on: 'right'
      },
      cancelIcon: {
        enabled: true
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 90, '.mb-left-nav-cont')
      },
      scrollTo: true,
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      }
    })
    _this.tour.addStep({
      id: 'tour-kontakte-mitarbeiter',
      text: stepTexts.step4,
      attachTo: {
        element: '.mb-kontakte .mb-kontakte-mitarbeiter',
        on: 'auto'
      },
      cancelIcon: {
        enabled: true
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: false
    })
    _this.tour.addStep({
      id: 'tour-kontakte-funktionen',
      text: stepTexts.step5,
      attachTo: {
        element: '.mb-kontakte .mb-kontakte-funktionen',
        on: 'auto'
      },
      cancelIcon: {
        enabled: true
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: false
    })
    _this.tour.addStep({
      id: 'tour-kontakte-bild',
      text: stepTexts.step6,
      attachTo: {
        element: '.mb-kontakte .mb-kontakte-funktionen .mb-edit-img',
        on: 'left'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      cancelIcon: {
        enabled: true
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 190)
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: true
    })
    _this.tour.addStep({
      id: 'tour-menue-profil',
      text: stepTexts.step7,
      attachTo: {
        element: '.mb-left-nav-cont .mb-nav-profil',
        on: 'right'
      },
      cancelIcon: {
        enabled: true
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 90, '.mb-left-nav-cont')
      },
      scrollTo: true,
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      }
    })
    _this.tour.addStep({
      id: 'tour-profil-bilder',
      text: stepTexts.step8,
      attachTo: {
        element: '.mb-route-profil .mb-marke-bilder',
        on: 'auto'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: false
    })
    _this.tour.addStep({
      id: 'tour-profil-videos',
      text: stepTexts.step9,
      attachTo: {
        element: '.mb-route-profil .mb-marke-videos',
        on: 'auto'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 80)
      },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: true
    })
    _this.tour.addStep({
      id: 'tour-profil-erfahrung',
      text: stepTexts.step10,
      attachTo: {
        element: '.mb-route-profil .mb-marke-erfahrung',
        on: 'auto'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 80)
      },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: true
    })
    _this.tour.addStep({
      id: 'tour-profil-meldungen',
      text: stepTexts.step11,
      attachTo: {
        element: '.mb-route-profil .mb-marke-meldungen',
        on: 'auto'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 80)
      },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: true
    })
    if (membershipType.MembershipTypeID < 3) {
      _this.tour.addStep({
        id: 'tour-profil-shortprofile',
        text: stepTexts.step12,
        attachTo: {
          element: '.mb-route-profil .mb-preview-shortprofile',
          on: 'auto'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
        },
        scrollToHandler: function (elm) {
          _this.scrollToTarget(elm, 80)
        },
        cancelIcon: {
          enabled: true
        },
        buttons: [
          {
            text: stepTexts.buttonnext,
            action: function () { _this.nextStep() }
          }
        ],
        scrollTo: true
      })
    }
    _this.tour.addStep({
      id: 'tour-profil-fair',
      text: stepTexts.step13,
      attachTo: {
        element: '.mb-route-profil .mb-preview-fair',
        on: 'auto'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 80)
      },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ],
      scrollTo: true
    })
    _this.tour.addStep({
      id: 'tour-menue-membership',
      text: stepTexts.step14,
      attachTo: {
        element: '.mb-left-nav-cont .mb-nav-membership',
        on: 'right'
      },
      cancelIcon: {
        enabled: true
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 90, '.mb-left-nav-cont')
      },
      scrollTo: true,
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      }
    })
    _this.tour.addStep({
      id: 'tour-booking-membership',
      text: stepTexts.step15,
      attachTo: {
        element: '.app-route-membership .checkout-container',
        on: 'auto'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ]
    })
    _this.tour.addStep({
      id: 'tour-menue-top-nl-chat',
      text: stepTexts.step16,
      attachTo: {
        element: '.mb-left-nav-cont .mb-nav-top-nl-chat',
        on: 'right'
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [0, 30] } }]
      },
      scrollToHandler: function (elm) {
        _this.scrollToTarget(elm, 90, '.mb-left-nav-cont')
      },
      scrollTo: true,
      cancelIcon: {
        enabled: true
      },
      buttons: [
        {
          text: stepTexts.buttonnext,
          action: function () { _this.nextStep() }
        }
      ]
    })
    _this.tour.addStep({
      id: 'tour-end',
      text: stepTexts.step17,
      buttons: [
        {
          text: stepTexts.buttonfinish,
          action: function () {
            _this.tour.complete()
          }
        }
      ]
    })
  }

  startTour () {
    this.tour.start()
    this.currentStep = 1
  }

  nextStep () {
    this.currentStep += 1
    if (this.tour == null) this.next()
    else this.tour.next()
    this.calculateProcessBar()
  }

  addStep (stepOptions) {
    this.tour.addStep(stepOptions)
  }

  calculateProcessBar () {
    const stepCount = this.tour.steps.length
    const elm = document.getElementsByClassName('tour-state-current')
    if (elm.length > 0) elm[elm.length - 1].style.cssText = 'width: ' + ((100 / stepCount) * this.currentStep) + '%'
  }

  scrollToTarget (elm, offset = 90, parentElm = 'html, body') {
    $(parentElm).animate({
      scrollTop: document.documentElement.scrollTop + elm.getBoundingClientRect().top - offset
    }, 'slow')
    // window.scrollTo(0, document.documentElement.scrollTop + elm.getBoundingClientRect().top - offset)
  }
}
