<template>
  <div class="mb-news">
    <div id="news" class="container mb-marke-meldungen mt-5 p-0">
      <h5 class="mb-card-title">
        {{ $t("page.profile.news.title") }}
      </h5>
      <div class="card mb-5">
        <div class="card-body">
          <div
            v-if="articles.length && !loading"
            class="border-bottom pb-2 mb-4"
          >
            <div
              v-for="article in computedArticles"
              :key="article.ArticleID"
              class="row article-row"
            >
              <div class="col-2 pl-0 mt-1">
                <div
                  :class="'mb-news-type mb-news-type-' + article.ArticleTypeID"
                >
                  {{ getNewsTypeCaption(article.ArticleTypeID) }}
                </div>
              </div>
              <div class="col-7 mt-2">
                <div class="mb-news-tbl-title">
                  <strong>{{ article.PublishingDate | formatDate }}:</strong>
                  {{ article.Title }}
                </div>
              </div>
              <div class="col text-right">
                <a
                  href="javascript:"
                  class="edit-link me-3"
                  @click="editNews(article)"
                  ><i class="fa fa-edit" /> {{ $t("button.edit") }}</a
                >
                <a
                  href="javascript:"
                  class="delete-link"
                  @click="deleteNews(article)"
                  ><i class="fa fa-remove" /> {{ $t("button.delete") }}</a
                >
              </div>
            </div>
          </div>
          <div v-else class="border-bottom pb-2 mb-4">
            <p v-if="articles.length === 0 && !loading">
              {{ $t("page.profile.news.nonews") }}
            </p>
            <p v-if="loading">
              {{ $t("page.profile.news.loading") }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <span class="text-muted mb-3"
              >{{$t('page.profile.news.counter', {
                articles: articles.length,
                total: articlesCount.total
              })}}</span
            >
            <button
              v-if="computedIsMoreToLoad"
              @click="loadMoreData"
              class="btn btn-primary"
              :disabled="computedIsLoadMoreIsDisabled"
            >
              {{
                $t("page.contactrequests.loadmore", {
                  count: computedMoreLoadCount,
                })
              }}
            </button>
          </div>
          <div class="row mt-4">
            <div class="col">
              <a
                href="javascript:"
                class="card-link icon-plus"
                @click.prevent="addEmptyNews"
                >{{ $t("page.profile.news.addnew") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="editModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        {{ computedEditCaption }}
      </template>
      <div v-if="isEditing" class="modal-body">
        <div
          class="bg-warning p-1 mb-3"
          v-t="'page.profile.news.newseditinfo'"
          v-if="!isEditable"
        ></div>
        <div class="row">
          <div class="col-3 text-center">
            <image-connected-selector
              v-model="currentNewsCopy.PreviewImageID"
              class="mb-edit-img"
            />
          </div>
          <div class="col-9">
            <div class="d-flex mb-4">
              <label class="font-weight-bold me-2 mt-1">{{
                $t("page.profile.news.newstitle")
              }}</label>

              <div class="input-group mb-2" v-if="isEditable">
                <input
                  v-model="currentNewsCopy.Title"
                  type="text"
                  name="title"
                  class="form-control newsTitleInput"
                  maxlength="120"
                  required
                  :title="this.$t('validation.maxchars', { chars: 120 })"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    {{ currentTitleLength }} / 120
                  </div>
                </div>
              </div>
              <h4 v-if="!isEditable">{{ getCurrentNewsCopy.Title }}</h4>
            </div>
            <custom-editor v-model="currentHTML" v-if="isEditable" />
            <div
              v-if="!isEditable"
              v-html="getEditContent"
              class="mb-news-edit"
            ></div>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="danger" size="sm" class="float-left" @click="cancel">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="saveCurrentNews(ok)"
        >
          {{ $t("button.save") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import CustomEditor from '../../../components/CustomEditor'
import ImageConnectedSelector from '../../../components/ImageConnectedSelector'

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY')
  }
})

export default {
  name: 'BrandProfileNews',
  components: {
    ImageConnectedSelector,
    CustomEditor
  },
  props: [],
  data: function () {
    return {
      articles: [],
      articlesCount: {
        loaded: 0,
        total: 0
      },
      disabledLoadMore: false,
      currentNews: {},
      currentNewsCopy: {},
      currentHTML: '',
      loading: false
    }
  },
  computed: {
    isEditing () {
      return typeof this.currentNewsCopy.Title !== 'undefined'
    },
    computedEditCaption () {
      if (this.currentNewsCopy.ArticleID === -1) {
        return this.$t('page.profile.news.new')
      }
      return this.$t('page.profile.news.edit', {
        contentType: this.getNewsTypeCaption(
          this.currentNewsCopy.ArticleTypeID
        )
      })
    },
    currentTitleLength () {
      return typeof this.currentNewsCopy.Title !== 'undefined' &&
        this.currentNewsCopy.Title !== null
        ? this.currentNewsCopy.Title.length
        : 0
    },
    emptyNews: function () {
      return {
        ArticleID: -1,
        Status: 1,
        Title: null,
        TeaserContentID: null,
        TextContent: null,
        ContentOld: null,
        BrandID: this.currentBrand.BrandID,
        PreviewImageID: null
      }
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    preloads: function () {
      return this.app.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    markenmeldungTypeId () {
      if (this.preloads.articletypes.length) {
        return this.preloads.articletypes.find((type) => {
          return type.Name === 'Markenmeldung'
        }).ArticleTypeID
      } else {
        return null
      }
    },
    markenmeldungTypeIds () {
      if (this.preloads.articletypes.length) {
        return this.preloads.articletypes
          .filter((type) => {
            return (
              type.Name === 'Markenmeldung' ||
              type.Name === 'Storys' ||
              type.Name === 'News'
            )
          })
          .map((Type) => {
            return Type.ArticleTypeID
          })
          .join(',')
      } else {
        return null
      }
    },
    currentContent: {
      get: function () {
        if (
          this.currentNews.TextContent !== null &&
          this.currentNews.TextContent.ContentID > 0
        ) {
          return this.currentNews.TextContent.Body
        }

        return this.currentNews.ContentOld
      },
      set: function (newValue) {
        if (
          this.currentNews.TextContent !== null &&
          this.currentNews.TextContent.ContentID > 0
        ) {
          this.currentNews.TextContent.Body = newValue
        } else {
          this.currentNews.ContentOld = newValue
        }
      }
    },
    computedArticles () {
      return this.articles.slice().sort((a, b) => {
        const aMoment = moment(a.CreationDate).unix()
        const bMoment = moment(b.CreationDate).unix()

        if (aMoment < bMoment) {
          return 1
        }

        if (bMoment < aMoment) {
          return -1
        }

        return 0
      })
    },
    getEditContent () {
      return this.currentHTML
    },
    getCurrentNewsCopy () {
      return this.currentNewsCopy
    },
    isEditable () {
      return this.currentNewsCopy.ArticleTypeID !== 23
    },
    computedMoreLoadCount () {
      return this.articlesCount.total - this.articlesCount.loaded
    },
    computedIsMoreToLoad () {
      return this.computedMoreLoadCount > 0
    },
    computedIsLoadMoreIsDisabled () {
      return this.disabledLoadMore === true
    }
  },
  watch: {
    'app.hasCurrentBrand': function () {
      this.loadData()
    }
  },
  mounted: function () {
    this.loadData()
  },
  methods: {
    editNews (News) {
      this.currentNews = News
      this.currentNewsCopy = Object.assign({}, this.currentNews)
      this.currentHTML = String(this.currentContent || '')
      this.$refs.editModal.show()
    },
    addEmptyNews () {
      this.editNews(Object.assign({}, this.emptyNews))
    },
    saveArticle (article) {
      return new Promise((resolve, reject) => {
        if (article.ArticleID > 0) {
          resolve(article.ArticleID)
        } else {
          this.app.API._put('article', {
            ArticleID: -1,
            Status: 1,
            Title: article.Title,
            ArticleTypeID: this.markenmeldungTypeId,
            BrandID: this.currentBrand.BrandID,
            PublishingDate: moment().toISOString(true),
            CreationDate: moment().toISOString(true),
            PreviewImageID: article.PreviewImageID
          })
            .then((response) => {
              const newArticleID = response.body.Result.NewID
              Promise.all(
                [1, 2, 3].map((PortalID) => {
                  return this.app.API._put('articleportal', {
                    ArticleID: newArticleID,
                    PortalID
                  })
                })
              ).then(() => {
                this.articlesCount.total += 1
                this.articlesCount.loaded += 1
                resolve(newArticleID)
              })
            })
            .catch(reject)
        }
      })
    },
    saveContents () {
      return new Promise((resolve, reject) => {
        new Promise((resolve, reject) => {
          if (this.currentNews.TextContentID > 0) {
            this.app.API._put('content', {
              ContentID: this.currentNews.TextContentID,
              Title: this.currentNews.Title,
              Body: this.currentHTML
            })
              .then(resolve)
              .catch(reject)
          } else {
            this.app.API._put('content', {
              ContentID: -1,
              Title: this.currentNews.Title,
              Body: this.currentHTML
            })
              .then((response) => {
                this.currentNews.TextContentID = response.body.Result.NewID
                resolve()
              })
              .catch(reject)
          }
        })
          .then(() => {
            this.app.API._put('article', {
              ArticleID: this.currentNews.ArticleID,
              Title: this.currentNews.Title,
              TextContentID: this.currentNews.TextContentID,
              ModificationDate: moment().toISOString(true),
              PreviewImageID: this.currentNews.PreviewImageID
            })
              .then(() => {
                this.app.API._post('article', {
                  ArticleID: this.currentNews.ArticleID,
                  FullSubEntities: true
                })
                  .then((response) => {
                    resolve(response.body.Result.Data)
                  })
                  .catch(reject)
              })
              .catch(reject)
          })
          .catch(reject)
      })
    },
    deleteNews (news) {
      this.$swal({
        title: this.$t('popupmessage.warn.areyousuretitle'),
        text: this.$t('popupmessage.warn.areyousuretitle', {
          news: news.Title
        }),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('button.yesdelete'),
        cancelButtonText: this.$t('button.no')
      }).then((result) => {
        if (typeof result.value !== 'undefined' && result.value === true) {
          news.PortalIDs = news.PortalIDs || []
          Promise.all(
            news.PortalIDs.map((PortalID) => {
              return this.app.API._delete(
                ['articleportal', news.ArticleID, PortalID].join('/')
              )
            })
          ).then(() => {
            const deleteString = 'article/' + news.ArticleID
            this.app.API._delete(deleteString).then(() => {
              this.articles = this.articles.filter((article) => {
                return article.ArticleID !== news.ArticleID
              })
              this.$swal(
                this.$t('popupmessage.success.deleted'),
                this.$t('popupmessage.success.newsdeleted'),
                'success'
              )
            })
          })
        }
      })
    },
    saveCurrentNews (okModal) {
      if (!this.currentNewsCopy.Title) {
        return this.$swal(
          this.$t('popupmessage.error.title'),
          this.$t('popupmessage.error.enteratitle'),
          'error'
        )
      }

      if (!this.currentHTML) {
        return this.$swal(
          this.$t('popupmessage.error.title'),
          this.$t('popupmessage.error.enteratext'),
          'error'
        )
      }

      const isNew = this.currentNewsCopy.ArticleID === -1

      this.currentNews = Object.assign(this.currentNews, this.currentNewsCopy)

      if (
        this.currentNews.TextContent !== null &&
        this.currentNews.TextContent.ContentID > 0
      ) {
        this.currentNews.TextContent.Body = this.currentHTML
      } else {
        this.currentNews.ContentOld = this.currentHTML
      }

      this.saveArticle(this.currentNews).then((saveArticleID) => {
        this.currentNews.ArticleID = saveArticleID
        this.saveContents(this.currentNews).then((updatedNews) => {
          if (isNew) this.articles.push(updatedNews)
          this.currentNews = {}
          this.currentNewsCopy = {}
          okModal()
        })
      })

      // add article entry
    },
    loadMoreData () {
      this.loadData(true)
    },
    loadData (append = false) {
      if (!this.currentBrand.BrandID) return

      const totalPerPage = 5

      if (append === false) this.loading = true
      this.disabledLoadMore = true
      this.app.API._post('articles', {
        ResFrom: this.articlesCount.loaded + 1,
        ResTo: this.articlesCount.loaded + totalPerPage,
        FullSubEntities: true,
        Filter: [
          {
            Field: 'ArticleTypeID',
            Operator: 'IN',
            Value: this.markenmeldungTypeIds
          },
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          }
        ],
        OrderBy: [
          { Field: 'PublishingDate', SortOrder: 'DESC' },
          { Field: 'ArticleID', SortOrder: 'DESC' }
        ]
      })
        .then((response) => {
          if (append === true) {
            this.articles = this.articles.concat(response.body.Result.Data)
          } else {
            this.articles = response.body.Result.Data
            this.articlesCount.total = response.body.Result.RecordCount
          }
          this.articlesCount.loaded = this.articles.length
        })
        .finally(() => {
          this.loading = false
          this.disabledLoadMore = false
        })
    },
    getNewsTypeCaption (articleType) {
      let result = ''
      switch (articleType) {
        case 25:
          result = this.$t('page.profile.news.brandmessage')
          break
        case 24:
          result = this.$t('page.profile.news.story')
          break
        case 23:
          result = this.$t('page.profile.news.news')
          break
        default:
          result = articleType
      }

      return result
    }
  }
}
</script>

<style lang="css" scoped>
.mb-news .icon-plus {
  margin-left: 10px;
  font-weight: 700;
  position: relative;
  padding-left: 32px;
}
.mb-news .icon-plus::after {
  font-size: 30px;
  position: absolute;
  left: -5px;
  top: -3px;
}
.newsTitleInput {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.article-row {
  margin-bottom: 0.75rem;
  padding: 0.5rem;
}
.article-row span {
  line-height: 38px;
}
.article-row:hover {
  background-color: #eee;
}
.edit-link {
  color: #72bb53;
}
.delete-link {
  color: #db3a26;
}
.edit-link,
.delete-link {
  line-height: 2.5rem;
}
.edit-link:hover,
.delete-link:hover {
  opacity: 0.75;
  text-decoration: none;
}
.mb-news-edit {
  max-height: 400px;
  min-height: 200px;
  overflow: auto;
}
.mb-news-type {
  border-radius: 15px;
  padding: 5px 6px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}
.mb-news-type-25 {
  background-color: yellowgreen;
}
.mb-news-type-24 {
  background-color: lightblue;
}
.mb-news-type-23 {
  background-color: yellow;
}
.mb-news-tbl-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
