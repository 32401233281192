<template>
  <div class="mb-main-content" v-if="hasCurrentBrand">
    <div
      v-if="showGiftPanel && !closeGift & !showAVVInfo"
      class="mb-gift mb-4"
      >
      <i
        class="fa fa-close"
        @click="closeGiftPanel()"
      ></i>
      <div class="row">
        <div class="col-lg-2 text-center">
          <img :src="require('../assets/images/icons/gift.svg')" style="width: 110px">
        </div>
        <div class="col-lg-6 d-flex justify-content-center align-items-center">
          <div>
            <h1>Holen Sie sich jetzt ihr Geschenk ab!</h1>
            <div>Ihr FranchisePORTAL-Team hält eine Überraschung für Sie bereit</div>
          </div>
        </div>
        <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <router-link
          to="/geschenk"
          class="mb-btn-gift"
        >
        Jetzt Geschenk auspacken
        </router-link>
        </div>
      </div>
    </div>
    <div
      v-if="showAVVInfo"
      class="mb-gift mb-avv"
      >
      <div class="row">
        <div class="col-lg-2 text-center">
          <i class="fa fa-pencil-square-o"></i>
        </div>
        <div class="col-lg-6 d-flex justify-content-center align-items-center">
          <div>
            <h1 v-t="'avvbanner.notsigned'"></h1>
            <div class="mt-4" v-t="'avvbanner.pleasesign'"></div>
          </div>
        </div>
        <div class="col-lg-4 d-flex justify-content-center align-items-center">
        <a
          href="javascript:"
          @click="signAVVContract"
          class="mb-btn-gift"
          v-t="'avvbanner.signcontract'"
        ></a>
        </div>
      </div>
    </div>
    <div
      class="mb-membership-promo"
      v-if="0 && app.currentMembership && !app.hasActiveMembership && !app.membershipPlaned"
    >
      <h1 v-t="'page.dashboard.banner.title'"></h1>
      <h2 v-if="showCoffeeText" v-t="'page.dashboard.banner.profilereadytext'"></h2>
      <h2 v-else v-t="'page.dashboard.banner.onlyafewclicks'"></h2>
      <div class="row">
        <div class="col-xl-6">
          <div class="mb-compare">
            <div class="mb-free">
              <h3 v-t="'page.dashboard.banner.freeprofile.title'"></h3>
              <ul>
                <li><i class="fa fa-check"></i>{{$t('page.dashboard.banner.freeprofile.freefp')}}</li>
                <li><i class="fa fa-check"></i>{{$t('page.dashboard.banner.freeprofile.freeug')}}</li>
                <li><i class="fa fa-times"></i>{{$t('page.dashboard.banner.list3')}}</li>
                <li><i class="fa fa-times"></i>{{$t('page.dashboard.banner.list4')}}</li>
                <li><i class="fa fa-times"></i>{{$t('page.dashboard.banner.list5')}}</li>
                <li><i class="fa fa-times"></i>{{$t('page.dashboard.banner.list6')}}</li>
                <li><i class="fa fa-times"></i>{{$t('page.dashboard.banner.list7')}}</li>
                <li><i class="fa fa-times"></i>{{$t('page.dashboard.banner.list8')}}</li>
              </ul>
            </div>
            <div class="mb-member">
              <h3 v-t="'page.dashboard.banner.membership'"></h3>
              <ul>
                <li>{{$t('page.dashboard.banner.presentationfp')}}<i class="fa fa-check"></i></li>
                <li>{{$t('page.dashboard.banner.presentationug')}}<i class="fa fa-check"></i></li>
                <li>{{$t('page.dashboard.banner.list3')}}<i class="fa fa-check"></i></li>
                <li>{{$t('page.dashboard.banner.list4')}}<i class="fa fa-check"></i></li>
                <li>{{$t('page.dashboard.banner.list5')}}<i class="fa fa-check"></i></li>
                <li>{{$t('page.dashboard.banner.list6')}}<i class="fa fa-check"></i></li>
                <li>{{$t('page.dashboard.banner.list7')}}<i class="fa fa-check"></i></li>
                <li>{{$t('page.dashboard.banner.list8')}}<i class="fa fa-check"></i></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="mb-badge-cont">
            <div class="mb-big-badge" v-if="!app.isDiscountAvailable">
              <h4 v-html="$t('page.dashboard.banner.badge.title')"></h4>
              <div>{{$t('page.dashboard.banner.badge.sub')}}<br>{{$t('page.dashboard.banner.badge.cancelableanytime')}}</div>
            </div>
            <div class="mb-big-badge" v-else>
              <h3 v-html="$t('page.dashboard.banner.badge.discounttitle')"></h3>
              <div>{{$t('page.dashboard.banner.badge.discountsub')}}<br>{{$t('page.dashboard.banner.badge.cancelableanytime')}}</div>
            </div>
            <router-link to="/mitgliedschaft-buchen" class="btn">{{$t('page.dashboard.banner.btnmembership')}}</router-link>
          </div>
        </div>
      </div>
      <similar-brands-slider :specialSector="this.currentBrand.SpecialSectorID" :title="$t('testimonials.members.title')" />
    </div>

    <ContentBanner v-if="!showGiftPanel&&!showAVVInfo&&currentAvailableUpgrade!==null" v-model="currentAvailableUpgrade" />

    <h1>{{ $t('menue.dashboard') }}</h1>

    <MembershipProgressBanner v-if="!app.isActiveMember" />
    <BrandProfileDefault />
  </div>
</template>

<script>
import BrandProfileDefault from './Brand/Profile/1_Default'
import ContentBanner from '../components/ContentBanner.vue'
import SimilarBrandsSlider from '../components/SimilarBrandsSlider.vue'
import MembershipProgressBanner from '../components/3.0/MembershipProgressBanner.vue'

export default {
  components: {
    BrandProfileDefault,
    ContentBanner,
    SimilarBrandsSlider,
    MembershipProgressBanner
  },
  name: 'Dashboard',
  props: [],
  data: function () {
    return {
      closeGift: false
    }
  },
  computed: {
    hasCurrentBrand () {
      return typeof this.currentBrand.BrandID !== 'undefined'
    },
    currentBrand () {
      const current = this.app.currentBrand || {}
      return current
    },
    isActiveMember () {
      return this.app.isActiveMember
    },
    currentBrandSlug () {
      return (
        this.app.getSlug(this.currentBrand.Name, {
          lang: 'de',
          custom: {
            '+': ''
          }
        }) +
        '-m-' +
        this.currentBrand.BrandID
      )
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    },
    showGiftPanel: function () {
      return this.app.giftAvailable() && localStorage.getItem('FPMB_hideGift') !== 'true'
    },
    showAVVInfo: function () {
      return (this.currentBrand.Membership === 3 || this.app.membershipPlaned) &&
             this.currentBrand.CompletedAVV !== 1
    },
    currentAvailableUpgrade: function () {
      return this.app.isMembershipUpgradeAvailable
    },
    showCoffeeText: function () {
      return this.currentBrand.Membership === 2 &&
             this.currentBrand.StatusID === 5 &&
             this.currentBrand.SDStatus === 5
    }
  },
  created: function () {
    if (typeof this.currentBrand.BrandID !== 'undefined' && !this.hasCurrentBrand) {
      this.$router.push('/markenauswahl')
    }
  },
  methods: {
    signAVVContract () {
      localStorage.setItem('returnLocationAfterAVVSetup', 'dashboard')
      this.$router.push('/avv-unterzeichnen')
    },
    closeGiftPanel () {
      localStorage.setItem('FPMB_hideGift', true)
      this.closeGift = true
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/dashboard') {
        const firstTour = localStorage.getItem('first_tour')
        if (firstTour == null && this.currentBrand.Membership != null && this.currentBrand.Membership < 3) {
          localStorage.setItem('first_tour', true)
          this.app.startTour()
        }
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style lang="less" scoped>
.mb-first-container {
  margin-top: 80px;
}
.dashboard-logo {
  max-width: 350px;
}
.blueCard {
  background-color: #019FE0;
  border-radius: 10px;
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 1rem;
}
.blueCard span {
  text-transform: uppercase;
  font-weight: bold;
}
</style>
