<template>
  <div>
    <section class="mb-top-advantages">
      <div class="container">
        <h2 v-t="'page.topbrand.morevisibility'"></h2>
        <ul class="mb-advs">
          <li>
            <div class="mb-adv">
              <div class="mb-adv-header">
                <img :src="require('../../assets/images/flags/de.svg')">
                <span>
                  <h3 v-t="'page.topbrand.topbrand'"></h3>
                  <span v-t="'page.topbrand.fpde'"></span>
                </span>
              </div>
              <div class="mb-adv-content">
                <ul>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.additionalpresence') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.bestplace') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.visitorspermonthde') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.pageviewspermonthde') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.noexpenditure') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.maxvisibility') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.highlightingde') }}</li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="mb-adv">
              <div class="mb-adv-header">
                <img :src="require('../../assets/images/flags/at.svg')">
                <span>
                  <h3 v-t="'page.topbrand.topbrand'"></h3>
                  <span v-t="'page.topbrand.fpat'"></span>
                </span>
              </div>
              <div class="mb-adv-content">
                <ul>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.additionalpresence') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.bestplace') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.visitorspermonthat') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.pageviewspermonthat') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.noexpenditure') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.maxvisibility') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.highlightingatch') }}</li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="mb-adv">
              <div class="mb-adv-header">
                <img :src="require('../../assets/images/flags/ch.svg')">
                <span>
                  <h3 v-t="'page.topbrand.topbrand'"></h3>
                  <span v-t="'page.topbrand.fpch'"></span>
                </span>
              </div>
              <div class="mb-adv-content">
                <ul>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.additionalpresence') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.bestplace') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.visitorspermonthch') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.pageviewspermonthch') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.noexpenditure') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.maxvisibility') }}</li>
                  <li><i class="fa fa-check-circle"></i>{{ $t('page.topbrand.highlightingatch') }}</li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="mb-top-desc-video">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <iframe src="https://www.youtube-nocookie.com/embed/o4h9UDxv4j8" allowfullscreen="" allow="autoplay; encrypted-media; picture-in-picture" title="" width="100%" height="250" frameborder="0"></iframe>
          </div>
          <div class="col-lg-7" v-html="$t('page.topbrand.videodesc')"></div>
        </div>
      </div>
    </section>
    <section class="mb-top-brand">
      <div class="container">
        <div class="mb-top-container">
          <div class="row">
            <div class="col-lg-2">
              <img :src="require('../../assets/images/icons/top-marke-150.png')">
            </div>
            <div class="col-lg-10">
              <h4 v-t="'page.topbrand.inoneview'"></h4>
              <div v-html="$t('page.topbrand.inoneviewdesc')"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TopBrandShowcase',
  props: [],
  data: function () {
    return {}
  },
  computed: {
  },
  methods: {
  },
  mounted: function () {
  }
}
</script>

<style lang="less" scoped>
  .mb-top-advantages {
    background-color: #f3f3f3;
    padding-top: 60px;
    padding-bottom: 60px;

    h2 {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 30px;
      text-align: center;
      padding-bottom: 20px;
    }

    .mb-advs {
      padding-left: 0px;
      display: flex;
      justify-content: space-between;
      list-style: none;
      align-items: stretch;

      > li {
        width: 32%;
        min-width: 320px;
      }
    }

    .mb-adv {
      box-shadow: 0px 1px 8px rgba(0,0,0,0.16);
      width: 100%;
      height: 100%;
      background-color: #fff;
    }

    .mb-adv-header {
      background-color: #fff;
      padding: 10px 20px;
      display: flex;
      align-items: center;

      img {
        width: 60px;
        height: 40px;
        margin-right: 20px;
      }

      h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0px;
        text-transform: uppercase;
      }
    }

    .mb-adv-content {
      padding: 20px;
      font-size: 14px;
      border-top: 1px solid #000;

      ul {
        list-style: none;
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        font-size: 12px;

        li {
          margin-bottom: 20px;
          padding-left: 20px;

          > i {
            position: relative;

            &::before {
              font-size: 20px;
              color: #72bb53;
              position: absolute;
              left: -25px;
              top: -15px;
            }
          }
        }
      }
    }
  }

  .mb-top-desc-video {
    font-size: 14px;
    background-color: #f3f3f3;
    padding-bottom: 60px;
    padding-top: 20px;

    h5 {
      font-weight: 700;
      font-size: 14px;
    }

    .row {
      align-items: center;
    }
  }

  .mb-top-brand {
    background-color: #005578;
    color: #fff;

    .container {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
    }

    .mb-top-container {
      border: 2px solid #fff;
      padding: 0px 20px;
    }

    .row {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    li {
      padding: 4px 0;
    }

    a:hover {
      color: #fff;
    }
  }
</style>
