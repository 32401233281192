<template>
  <div>
    <div
      id="erfahrung"
      class="container mb-marke-erfahrung mt-5 p-0"
    >
      <h5 class="mb-card-title">
        {{ $t('page.profile.testimonial.title') }}
      </h5>
      <div class="card mb-5">
        <div class="card-body">
          <p v-show="experiences.length===0">
            {{ $t('page.profile.testimonial.notestimonial') }}
          </p>
          <div
            v-for="experience in experiences"
            :key="experience.FairExperienceID"
            class="row experienceRow"
          >
            <div class="col-3 text-center">
              <img v-if="experience.MediumID" class="img-fluid" :src="FPHelper.getImagePathFromMedium(experience.Medium)" />
              <p v-else class="p-5 text-center d-block">{{ $t('image.nopicture') }}</p>
            </div>
            <div class="col-9">
              <h6 class="font-weight-bold">
                {{ $t('page.profile.testimonial.quote') }}
              </h6>
              <div class="content-holder" v-html="experience.Text"></div>
              <div class="row mt-3">
                <div class="col d-flex">
                  <label
                    for="fld_AuthorData"
                    class="font-weight-bold me-2"
                  >{{ $t('page.profile.testimonial.namelastnamecity') }}</label>
                  <p v-html="experience.AuthorData"></p>
                </div>
              </div>

              <a
                href="javascript:"
                class="btn btn-primary mt-2 mb-2 me-3"
                @click.prevent="editExperience(experience)"
              >{{ $t('page.profile.testimonial.edit') }}</a>
              <a
                href="javascript:"
                class="btn btn-danger"
                @click.prevent="doDelete(experience)"
              >{{ $t('page.profile.testimonial.delete') }}</a>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col">
              <a
                href="javascript:"
                class="card-link icon-plus"
                @click.prevent="addExperience"
              >{{ $t('page.profile.testimonial.addnew') }}</a>
            </div>
          </div>
        </div>
      </div>

    <b-modal ref="editModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        {{ computedEditCaption }}
      </template>
      <div class="modal-body">
          <div class="row experienceRow">
            <div class="col-3 text-center">
              <image-connected-selector
                v-model="currentExperienceCopy.MediumID"
                class="mb-edit-img"
                :default="defaultImage"
                :ratio="1"
                editClass="silhouette-testimonial"
                :resolution-hint="$t('image.recoprofilepicture')"
              />
            </div>
            <div class="col-9">
              <h6 class="font-weight-bold">
                {{ $t('page.profile.testimonial.quote') }}
              </h6>
              <custom-editor
                v-model="currentExperienceCopy.Text"
                maxlength="750"
              />
              <div class="row mt-3">
                <div class="col d-flex">
                  <label
                    for="fld_AuthorData"
                    class="font-weight-bold me-2 mt-1"
                  >{{ $t('page.profile.testimonial.namelastnamecity') }}</label>
                  <input
                    id="fld_AuthorData"
                    v-model="currentExperienceCopy.AuthorData"
                    type="text"
                    class="form-control"
                    :placeholder="this.$t('page.profile.testimonial.namelastnamecity')"
                    required
                  >
                </div>
              </div>
            </div>
          </div>

      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          size="sm"
          class="float-left"
          @click="cancel"
        >
          {{ $t('button.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="saveCurrentExperience(ok)"
        >
          {{ $t('button.save') }}
        </b-button>
      </template>
    </b-modal>

    </div>
  </div>
</template>

<script>
import FPHelper from '../../../helpers/FPHelper'
import ImageConnectedSelector from '../../../components/ImageConnectedSelector'
import CustomEditor from '../../../components/CustomEditor'

export default {
  name: 'BrandProfileExperiences',
  components: {
    ImageConnectedSelector,
    CustomEditor
  },
  props: [],
  data: function () {
    return {
      FPHelper,
      currentExperience: {},
      currentExperienceCopy: {}
    }
  },
  computed: {
    emptyExperience () {
      return {
        FairExperienceID: -1,
        FairID: this.messestand.FairID,
        MediumID: null,
        Text: '',
        AuthorData: ''
      }
    },
    defaultImage () {
      return require('../../../assets/images/platzhalter-portrait-weiss.jpg')
    },
    experiences: {
      get: function () {
        return this.messestand.FairExperiences || []
      },
      set: function (newExperiences) {
        this.app.messestand.FairExperiences = newExperiences
      }
    },
    app: function () {
      return this.$parent.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    computedEditCaption () {
      if (this.currentExperienceCopy.FairExperienceID === -1) {
        return this.$t('page.profile.testimonial.new')
      }
      return this.$t('page.profile.testimonial.edit')
    }
  },
  methods: {
    editExperience (Experience) {
      this.currentExperience = Experience
      this.currentExperienceCopy = Object.assign({}, this.currentExperience)
      this.$refs.editModal.show()
    },
    doDelete (experienceToDelete) {
      this.$swal({
        title: this.$t('popupmessage.warn.areyousuretitle'),
        text: this.$t('popupmessage.warn.tesimonialdeletemessage'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(result => {
        if (typeof result.value !== 'undefined') {
          const loader = this.app.startLoader()

          this.app.API._delete(
            ['fairexperience', '/', experienceToDelete.FairExperienceID].join(
              ''
            )
          ).then(() => {
            this.experiences = this.experiences.filter(experience => {
              return (
                experience.FairExperienceID !==
                experienceToDelete.FairExperienceID
              )
            })
            loader.hide()
          })
        }
      })
    },
    addExperience () {
      this.editExperience(Object.assign({}, this.emptyExperience))
    },
    saveCurrentExperience (okModal) {
      const experienceCheck = this.checkExperience(this.currentExperienceCopy)

      if (experienceCheck !== false) {
        return this.$swal(
          this.$t('popupmessage.warn.attentiontitle'),
          this.$t('popupmessage.warn.fillallfields'),
          'warning'
        )
      }

      this.currentExperience = Object.assign(this.currentExperience, this.currentExperienceCopy)
      if (this.currentExperience.FairExperienceID === -1) {
        this.experiences.unshift(Object.assign({}, this.currentExperience))
      }

      this.saveExperience(this.currentExperience).then(() => {
        okModal()
      })
    },
    checkExperience (experience) {
      if (experience.Text.length === 0) return 'Text'
      if (experience.AuthorData.length === 0) return 'AuthorData'
      return false
    },
    saveExperience (experience) {
      return new Promise((resolve, reject) => {
        const objectToSave = Object.assign({}, experience)

        delete objectToSave.Medium
        delete objectToSave.Author
        delete objectToSave.Video

        if (!objectToSave.SortDate) {
          objectToSave.SortDate = new Date().toISOString().substr(0, 19)
        }

        if (objectToSave.MediumID === 0 || objectToSave.MediumID === null) {
          objectToSave.NullFields = ['MediumID']
        }

        this.app.API._put('fairexperience/', objectToSave)
          .then(response => {
            if (objectToSave.FairExperienceID === -1) {
              experience.FairExperienceID = response.body.Result.NewID
            }
            this.app.loadBrand(this.currentBrand.BrandID)
              .then(resolve)
          })
          .catch(reject)
      })
    }
  }
}
</script>

<style lang="css">
.vdatetime {
  display: block;
  width: 100%;
}
.vdatetime .vdatetime-input {
  color: #005578 !important;
  border-radius: 20px !important;
  font-size: 16px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
.row.experienceRow {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
}
.content-holder {
  padding: 1rem;
  border: 2px dashed #ccc;
  /* background-color: #f2f2f2; */
}
</style>
