<template>
  <div>
    <a
      href="javascript:"
      class="mb-edit-img"
      @click.prevent="openChangeModal"
    >
      <img
        v-if="!hasNoImage"
        :src="imagePath"
        @error="imageHasError"
      >
      <span
        v-if="hasNoImage"
        class="no-image"
      >{{ $t('image.nopicture') }}</span>
      <div>
        <span class="lh-sm">
          <i class="fa fa-edit" />
          <br>
          {{ computedEditCaption }}
        </span>
      </div>
    </a>
    <b-modal ref="imageEditModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        {{ computedEditCaption }}
      </template>
      <div class="modal-body" :class="editClass">
        <div class="row mt-3">
          <div :class="'col-' + (resolutionHint ? '8' : '12')" class="position-relative">
            <div :class="editImageClass">
              <vue-cropper
                v-show="previewImageURL"
                ref="cropper"
                :src="imagePathCropScreen"
                :auto-crop-area="computedAutoCropArea"
                :view-mode="0"
                :aspect-ratio="ratio||NaN"
                :crop="handleCropChange"
                :ready="handleCropReady"
                fill-color="#fff"
                class="imageCropper"
                @ready="onReadyCropper"
              />
              <div v-if="showRotateButtons" class="form-group mt-2 d-flex justify-content-between">
                <div>
                  <button class="btn btn-light btn-sm me-2" @click="rotate('left')" v-if="imgType != 'logo'"><i class="fa fa-undo me-2" aria-hidden="true"></i>{{ $t('image.rotateleft') }}</button>
                  <button class="btn btn-light btn-sm me-2" @click="rotate()" v-if="imgType != 'logo'"><i class="fa fa-repeat me-2" aria-hidden="true"></i>{{ $t('image.rotateright') }}</button>
                  <button class="btn btn-light btn-sm me-2" @click="zoom(0.2)"><i class="fa fa-search-plus" aria-hidden="true"></i></button>
                  <button class="btn btn-light btn-sm me-2" @click="zoom(-0.2)"><i class="fa fa-search-minus" aria-hidden="true"></i></button>
                </div>
                <div>
                <b-button
                  variant="light"
                  size="sm"
                  class="me-3"
                  @click="cancelCrop()"
                >
                  {{$t('button.cancel')}}
                </b-button>
                <b-button
                  variant="success"
                  size="sm"
                  class=""
                  @click="confirmCrop()"
                >
                  {{$t('button.confirm')}}
                </b-button>
                </div>
              </div>
            </div>
            <div v-if="showPreview" style="text-align: center">
                <img
                  :src="previewImageURL"
                  class="img-fluid previewImage"
                >
                <div class="mt-2">
                  <b-button
                    variant="light"
                    size="sm"
                    class="me-3"
                    @click="showEditImage = true; showPreview = false"
                  ><i aria-hidden="true"><img src="/icons/edit_img.svg" width="18px" /></i>
                    {{ $t('image.edit') }}
                  </b-button>
                  <b-button
                    variant="light"
                    size="sm"
                    class=""
                    @click="showUpload = true; showPreview = false; removeUpload()"
                  ><i class="fa fa-refresh" aria-hidden="true"></i>
                    {{ $t('image.btnuploadnew') }}
                  </b-button>
                </div>
            </div>
            <div class="form-group" :class="uploadImageClass">
              <label>{{ $t('image.upload') }}</label>
              <div class="file-upload">
                <div class="file-upload-placeholder" v-on:dragover="uploadDragOver()" v-on:dragleave="uploadDragLeave()">
                  <input
                    v-show="!isUploading"
                    ref="fileUploadInput"
                    class="file-upload-input"
                    type="file"
                    @change="handleFileUpload"
                    accept="image/*"
                  >
                  <div class="drag-text">
                    <i class="fa fa-upload"></i>
                    <div>{{ $t('image.uploaddesc') }}</div>
                    <small
                      class="mt-2"
                    >
                      <strong>{{ $t('image.maxsize') }}</strong> 10MB
                    </small>
                  </div>
                </div>
              </div>
              <p v-show="isUploading">
                {{ $t('image.uploading') }}
              </p>
              <div v-if="!hasNoImage" class="mt-2">
                <b-button
                  variant="light"
                  size="sm"
                  class=""
                  @click="showUpload = false; showPreview = true"
                >
                  {{ $t('image.backtopreview') }}
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-4" v-if="resolutionHint">
            <div class="form-group">
              <label class="font-weight-bold">{{ $t('image.recommended') }}</label>
              <div v-html="resolutionHint"></div>
            </div>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer="{ ok }">
          <b-button
            v-show="!hasNoImage&&!isUploading&&showDeleteButton&&showFooter"
            variant="danger"
            size="sm"
            class="float-right"
            @click="handleRemoveCurrentImage"
          >
            {{ $t('image.delete') }}
          </b-button>
          <b-button
            v-show="!isUploading&&showFooter"
            variant="primary"
            size="sm"
            class="float-right"
            @click="handleSave(ok)"
          >
            {{ $t('button.save') }}
          </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import Config from '../config'
import $ from 'jquery'

export default {
  name: 'ImageConnectedSelector',
  components: {
    VueCropper
  },
  props: ['value', 'default', 'save', 'ratio', 'editCaption', 'resolutionHint', 'imgHint', 'imgType', 'editClass'],
  data: function () {
    return {
      currentImage: {},
      currentImageUpload: {
        MediumID: 0
      },
      isUploading: false,
      isUploaded: false,
      isError: false,
      previewImageURL: false,
      hasChanged: false,
      inputFilename: '',
      showUpload: true,
      showEditImage: false,
      showPreview: false,
      afterUpload: false,
      showFooter: false
    }
  },
  computed: {
    computedEditCaption () {
      return this.editCaption ? this.editCaption : this.$t('image.change')
    },
    hasNoImage () {
      return !(this.currentImage.MediumID > 0)
    },
    fileUploadInput () {
      return this.$refs.fileUploadInput
    },
    imageDriveURL () {
      return Config.MEDIA_API_URL
    },
    uploadFolderID () {
      return 5
    },
    imagePath () {
      if (this.currentImage.FileID > 0) {
        return [this.imageDriveURL, 'id/', this.currentImage.FileID].join('')
      }
      return this.default || ''
    },
    imagePathCropScreen () {
      return [this.imageDriveURL, 'id/', this.currentImageUpload.FileID].join(
        ''
      )
    },
    editModal () {
      return this.$refs.imageEditModal
    },
    API () {
      return this.$root.getApp().API
    },
    showRotateButtons () {
      return !(this.previewImageURL === false)
    },
    editImageClass () {
      return this.showEditImage ? '' : 'invisible position-absolute'
    },
    uploadImageClass () {
      return this.showUpload ? '' : 'invisible position-absolute'
    },
    computedAutoCropArea () {
      return (this.imgType === 'logo' && this.isUploaded ? 0.8 : 1)
    },
    showDeleteButton () {
      return this.imgType !== 'logo' && this.imgType !== 'mainpicture'
    }
  },
  watch: {
    value: function () {
      this.loadData()
    },
    currentImage: function () {
      this.showPreview = !this.hasNoImage
    }
  },
  mounted: function () {
    this.loadData()
    this.showPreview = !this.hasNoImage
  },
  methods: {
    cancelCrop () {
      this.showEditImage = false
      if (this.isUploaded) {
        this.isUploaded = false
        this.$refs.cropper.clear()
        this.showUpload = true
        this.removeUpload()
      } else {
        this.showPreview = true
        this.$refs.cropper.reset()
      }
    },
    confirmCrop () {
      this.showEditImage = false
      this.showPreview = true
      this.isUploaded = false
      this.showFooter = true
      this.showUpload = false
    },
    imageHasError () {
      this.isError = true
    },
    rotate (dir = '') {
      const rotateDegree = dir === 'left' ? -90 : 90
      this.$refs.cropper.rotate(rotateDegree)
      // this.$refs.cropper.reset()
    },
    onReadyCropper () {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
        this.$refs.cropper.reset()
      }, 50)
    },
    reset () {
      this.isError = false
      this.currentImage = {}
      this.currentImageUpload = {
        MediumID: 0
      }
      this.isUploading = false
      this.isUploaded = false
      this.previewImageURL = false
      this.hasChanged = false
      this.showUpload = true
      this.showEditImage = false
      this.inputFilename = ''
      this.showFooter = false
      this.removeUpload()
      if (this.$refs.cropper) {
        this.$refs.cropper.reset()
      }
    },
    /*
    getPreviewImageURL() {
      return this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    */
    handleCropReady () {
      this.isUploading = false
    },
    handleCropChange () {
      if (this.$refs.cropper == null) return
      const croppedCanvas = this.$refs.cropper.getCroppedCanvas({ fillColor: '#fff' })
      if (croppedCanvas != null) {
        this.hasChanged = true
        this.previewImageURL = croppedCanvas.toDataURL()

        if (this.isUploaded && this.afterUpload) {
          setTimeout(() => {
            this.setCanvasData()
          }, 500)
        }
      }
    },
    setCanvasData () {
      this.afterUpload = false
      const cropBox = this.$refs.cropper.getCropBoxData()
      let canvasData = this.$refs.cropper.getCanvasData()
      if (canvasData.width > canvasData.height) {
        this.$refs.cropper.setCanvasData({ width: cropBox.width, left: cropBox.left })
        canvasData = this.$refs.cropper.getCanvasData()
        const top = (cropBox.height / 2) - (canvasData.height / 2) + cropBox.top
        this.$refs.cropper.setCanvasData({ top })
      } else {
        this.$refs.cropper.setCanvasData({ height: cropBox.height, top: cropBox.top })
        canvasData = this.$refs.cropper.getCanvasData()
        const left = (cropBox.width / 2) - (canvasData.width / 2) + cropBox.left
        this.$refs.cropper.setCanvasData({ left })
      }
    },
    loadData () {
      return new Promise((resolve, reject) => {
        if (this.value !== null) {
          this.loadNewImage(this.value)
            .then(imageData => {
              this.currentImage = Object.assign({}, imageData)
              this.inputFilename = this.currentImage.Filename
              this.showUpload = this.hasNoImage && !this.isUploaded
              resolve()
            })
            .catch(reject)
        } else {
          this.currentImage = {}
          resolve()
        }
      })
    },
    handleRemoveCurrentImage () {
      this.currentImage = {}
      this.currentImageUpload = {
        MediumID: 0
      }
      this.isUploading = false
      this.previewImageURL = false
      this.hasChanged = false
      this.$emit('input', null)
      this.handleSave(() => {
        this.editModal.hide('imageEditScreen')
      })
    },
    resetFileInput () {
      this.fileUploadInput.type = 'text'
      this.fileUploadInput.type = 'file'
      this.inputFilename = ''
    },
    openChangeModal () {
      this.reset()
      this.loadData().then(() => {
        this.editModal.show('imageEditScreen')
      })
    },
    loadNewImage (id) {
      return new Promise((resolve, reject) => {
        if (!(id > 0)) {
          return resolve(this.currentImage)
        }

        const loadOptions = {
          fullSubEntities: true,
          filter: [
            {
              operator: 'EQ',
              field: 'MediumID',
              value: id
            }
          ],
          ResFrom: 1,
          ResTo: 1
        }

        this.API._post('media/', loadOptions)
          .then(response => {
            if (response.body.Result.Result !== 'Failed') {
              const data = Object.assign({}, response.body.Result.Data[0])
              this.currentImageUpload = data
              resolve(data)
            } else {
              reject(new Error('Keine Datei gefunden.', loadOptions))
            }
          })
          .catch(reject)
      })
    },
    baseName (str) {
      let base = String(str).substring(str.lastIndexOf('/') + 1)
      if (base.lastIndexOf('.') !== -1) { base = base.substring(0, base.lastIndexOf('.')) }
      return base
    },
    handleFileUpload (event) {
      this.isUploading = true

      this.readURL(event.target)
      const file = event.target.files[0]

      if (file.size > 1e7) {
        this.isUploading = false
        this.$swal(this.$t('popupmessage.warn.imagetobigtitle'), this.$t('popupmessage.warn.imagetobigmessage'), 'warning')
      } else {
        this.inputFilename = file.name

        const resizeImageData = new FormData()
        resizeImageData.append('file', file)

        axios
          .post(Config.IMAGE_RESIZE_FUNCTION, resizeImageData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            this.isUploading = false
            this.isUploaded = true
            this.isError = false
            this.$refs.cropper.replace(response.data, false)
            this.showEditImage = true
            this.showUpload = false
            this.afterUpload = true
          })
          .catch(() => {
            this.isUploading = false
            this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.imagenotfound'), 'error')
          })
      }
    },
    handleSave (ok) {
      if (this.previewImageURL === false) {
        this.emitChange(this.currentImageUpload)
        return ok()
      }

      this.isUploading = true
      const loader = this.$root.getApp().startLoader()

      this.uploadImageToApi()
        .then(uploadedImageId => {
          this.loadNewImage(uploadedImageId).then(imageData => {
            this.emitChange(imageData)
            loader.hide()
            this.isUploading = false
            ok()

            if (typeof this.save === 'function') {
              this.save(imageData)
            }
          })
        })
        .catch(error => {
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.onupload'))
          loader.hide()
          console.error(error)
          ok()
        })
    },
    emitChange (imageData) {
      this.$emit('input', imageData.MediumID)
      this.$emit('change')
      this.currentImage = Object.assign({}, imageData)
      this.currentImageUpload = Object.assign({}, imageData)
      this.$refs.cropper.replace(this.imagePathCropScreen, false)
      this.resetFileInput()
    },
    digestMessage: async function (message) {
      const msgUint8 = new TextEncoder().encode(message)
      const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray
        .map(b => b.toString(16).padStart(2, '0'))
        .join('')
      return hashHex
    },
    uploadImageToApi () {
      return new Promise((resolve, reject) => {
        new Promise(resolve => {
          const canvas = this.$refs.cropper.getCroppedCanvas({ fillColor: '#fff' })
          canvas.toBlob(function (blob) {
            resolve(blob)
          })
        })
          .then(async fileBlob => {
            let newUploadFilename = this.inputFilename

            if (newUploadFilename.indexOf('/blob') !== -1) {
              const fileExtension = ['', fileBlob.type.split('/').pop()].join(
                '.'
              )
              const hexHash = await this.digestMessage(await fileBlob.text())
              newUploadFilename = newUploadFilename.replace(
                '/blob',
                `/${hexHash}${fileExtension}`
              )
            }

            const uploadImageData = new FormData()
            uploadImageData.append('file', fileBlob, newUploadFilename)
            uploadImageData.append('folder', 'SystemRoot/legacy/fairpics/')
            uploadImageData.append('overwriteMode', 1)
            uploadImageData.append('identifier', -1)

            const loginFromStorage = localStorage.getItem('FPMB_Login')
            const login = loginFromStorage ? JSON.parse(loginFromStorage) : {}

            axios
              .post(this.imageDriveURL, uploadImageData, {
                headers: {
                  Accept: 'application/json',
                  Authorization: ['Bearer', login.token].join(' '),
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(response => {
                const error = response.data.Error !== 0
                if (error) return reject(error)

                const uploadedFileData = response.data.Data.shift()

                this.API._put('medium/', {
                  MediumID: -1,
                  FileID: uploadedFileData.identifier,
                  Filename: uploadedFileData.fullPath,
                  URL: uploadedFileData.fullPath,
                  FolderID: this.uploadFolderID,
                  Hash: uploadedFileData.hash,
                  Height: uploadedFileData.mediainfo.height,
                  Size: uploadedFileData.size,
                  State: 0,
                  TypeID: 1,
                  Width: uploadedFileData.mediainfo.width
                })
                  .then(response => {
                    resolve(response.body.Result.NewID)
                  })
                  .catch(reject)
              })
              .catch(reject)
          })
          .catch(reject)
      })
    },
    zoom (percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
    readURL (input) {
      if (input.files && input.files[0]) {
        const inputFileData = input.files[0]
        const reader = new FileReader()
        reader.onload = function (e) {
          $('.image-title').html(inputFileData.name)
        }
        reader.readAsDataURL(inputFileData)
      } else { this.removeUpload() }
    },
    removeUpload () {
      $('.file-upload-input').val('')
    },
    uploadDragOver () {
      $('.file-upload-placeholder').addClass('image-dropping')
    },
    uploadDragLeave () {
      $('.file-upload-placeholder').removeClass('image-dropping')
    }
  }
}
</script>

<style lang="scss" scoped>
.previewImage {
  max-height: 500px;
  border: 1px solid #ddd;
}
.imageCropper {
  max-width: 100%;
  max-height: 500px;
  min-height: 300px;
}
.img-container {
  /* Never limit the container height here */
  max-width: 100%;
}

.img-container img {
  /* This is important */
  width: 100%;
}
.no-image {
  display: block;
}
.mb-edit-img > a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  text-decoration: none;
}
.modal-body .btn {
  font-weight: 400;
}
.file-upload {
  background-color: #ffffff;
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.file-upload-placeholder {
  margin-top: 20px;
  border: 2px dashed #ccc;
  position: relative;
}
.image-dropping,
.file-upload-placeholder:hover {
  background-color: #eee;
  border: 2px dashed #00B363;
}
.drag-text {
  text-align: center;
  display: grid;
  place-content: center;
  height: 150px;
}
.drag-text i {
  margin-bottom: 20px;
  font-size: 30px;
}
</style>
<style lang="less">
.cropper-point {
  height: 15px !important;
  width: 15px !important;
  box-shadow: 0 1px 2px #8e8e8e;
  background-color: #fff;
  border-radius: 50%;

  &.point-e {
    margin-top: -8px;
    right: -8px;
  }

  &.point-n {
    margin-left: -8px;
    top: -8px;
  }

  &.point-w {
    left: -8px;
    margin-top: -8px;
  }

  &.point-s {
    bottom: -8px;
    margin-left: -8px;
  }

  &.point-ne {
    right: -8px;
    top: -8px;
  }

  &.point-nw {
    left: -8px;
    top: -8px;
  }

  &.point-sw {
    bottom: -8px;
    left: -8px;
  }

  &.point-se {
    bottom: -8px;
    right: -8px;
  }
}

.cropper-line {
  background-color: #fff;
}

.cropper-view-box {
  outline: 2px dashed rgba(255,255,2255,0.75);
}

.silhouette-portrait, .silhouette-testimonial {
  .cropper-view-box {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }

  .cropper-container img {
    z-index: -1;
    position: relative;
  }
}

.silhouette-portrait {
  .cropper-view-box {
    &::before {
      background-image: url(../assets/images/silhouette_portrait.svg);
    }
  }
}
.silhouette-testimonial {
  .cropper-view-box {
    &::before {
      background-image: url(../assets/images/silhouette_testimonial.svg);
    }
  }
}

</style>
