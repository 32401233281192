<template>
  <div v-if="currentBrand.SDStatus != null && currentBrand.SDStatus === 5">
    <div
      v-if="app.hasMessestand"
      id="messestandfp"
      class="container mb-marke-messe pt-5"
    >
      <h5 class="mb-card-title">
        {{ $t('page.profile.settings.titlefp') }}
      </h5>
      <div class="card shadow p-3 mb-5 bg-white">
        <div class="card-body">
          <form>
            <div class="row mb-4">
              <div class="col-3 text-center">
                <img
                  class="img-fluid"
                  src="../../../assets/images/ipad-FP.png"
                >

                <a
                  :href="`https://www.franchiseportal.de/franchise-unternehmen/${app.currentBrandSlug}?preview=fair`"
                  class="btn btn-sm btn-primary"
                  target="_blank"
                >
                  <i class="fa fa-search" />
                  {{ $t('button.preview') }}
                </a>
              </div>
              <div class="col-9">
                <div v-html="$t('page.profile.settings.descriptionfp')"></div>

                <h5 class="font-weight-bold">
                  {{ $t('page.profile.settings.settings') }}
                </h5>

                <div class="mb-list">
                  <label>{{ $t('page.profile.settings.slogan') }}</label>
                  <div class="charCounterInsideContainer">
                    <span class="charCounterInside">{{ String(messestand.Slogan).length }} / 100</span>
                    <input
                      v-model="messestand.Slogan"
                      type="text"
                      placeholder="Slogan"
                      class="form-control"
                      maxlength="100"
                      @input="registerChange"
                    >
                  </div>
                </div>
                <div>
                  <label><strong>{{ $t('page.profile.settings.shortdesc')}}</strong></label>
                  <div>
                    <content-editor
                      ref="selfSavingContentInstance1"
                      v-model="messestand.FeaturedSnippet"
                      maxlength="700"
                      @change="registerChange"
                    />
                  </div>
                </div>

                <div class="mb-color-select">
                  <a
                    href="javascript:"
                    @click.prevent="showMessestandColorEdit"
                  >
                    <span
                      class="logoBarColorSelected"
                      :style="`background-color: ${computedLogoBarColor}`"
                    /> {{ $t('page.profile.settings.editbrandcolor') }}
                  </a>
                </div>
                <div class="d-flex">
                  <label class="badge bg-secondary">ABC</label>
                  <a
                    href="javascript:"
                    class="ms-2"
                    @click.prevent="showMessestandTextEdit"
                  >{{ $t('page.profile.settings.editbranddesc') }}</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      id="markenseiteug"
      class="container mb-marke-markenseite pt-5"
    >
      <h5 class="mb-card-title">
        {{ $t('page.profile.settings.titleug') }}
      </h5>
      <div class="card shadow p-3 mb-5 bg-white">
        <div class="card-body">
          <form>
            <div class="row mb-4">
              <div class="col-3 text-center">
                <img
                  class="img-fluid"
                  src="../../../assets/images/ipad-UG.png"
                >
              </div>
              <div class="col-9">
                <div v-html="$t('page.profile.settings.descriptionug')"></div>

                <h5 class="font-weight-bold">
                  {{ $t('page.profile.settings.settings') }}
                </h5>
                <div class="mt-3">
                  <label><strong>{{ $t('page.profile.settings.shortdesc')}}</strong></label>
                  <div>
                    <content-editor
                      ref="selfSavingContentInstanceUG"
                      v-model="messestand.FeaturedSnippetUG"
                      maxlength="700"
                      @change="registerChange"
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <label><strong>{{ $t('page.profile.settings.companyProfile')}}</strong></label>
                  <div>
                    <custom-editor
                      v-model="rpsbrand.Profile"
                      @change="registerChange"
                    />
                  </div>
                </div>
                <div class="d-flex mt-3">
                  <label class="badge bg-secondary">ABC</label>
                  <a
                    href="javascript:"
                    class="ms-2"
                    @click.prevent="showMessestandTextUGEdit"
                  >{{ $t('page.profile.settings.editbranddesc') }}</a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      v-if="0"
      id="komparator"
      class="container mb-marke-komparator pt-5"
    >
      <h5 class="mb-card-title">
        {{ $t('page.profile.settings.komptitle') }}
      </h5>
      <div class="card shadow p-3 mb-5 bg-white">
        <div class="card-body">
          <form>
            <div class="row mb-4">
              <div class="col-4 text-center">
                <img
                  src="../../../assets/images/fp-ug-logo.png"
                  class="mb-4 img-fluid"
                >
              </div>
              <div class="col-8">
                <div v-html="$t('page.profile.settings.descriptionkp')"></div>

                <h6 class="font-weight-bold">
                  {{ $t('page.profile.settings.settings') }}
                </h6>

                <div class="custom-control custom-switch">
                  <input
                    id="komp-active"
                    v-model="messestand.UsedInSimilarSystems"
                    type="checkbox"
                    class="custom-control-input"
                  >
                  <label
                    class="custom-control-label"
                    for="komp-active"
                  >
                    <span v-if="messestand.UsedInSimilarSystems" v-html="$t('page.profile.settings.kompuseinsimilarsystemactive')"></span>
                    <span v-else v-html="$t('page.profile.settings.kompuseinsimilarsystemdeactivate')"></span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <b-modal ref="messestandColorEditModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        {{ $t('page.profile.settings.editbrandcolor') }}
      </template>
      <div v-if="messestandColorCopy!==null" class="modal-body">

        <div class="colorChanger">
          <color-picker
            v-model="messestandColorCopy"
            :height="180"
            :width="180"
            :start-color="`#${messestandColorCopy}`"
          />
          <p>
            <input
              v-model="messestandColorCopy"
              type="text"
            >
            <svg
              height="32"
              width="32"
            >
              <circle
                cx="16"
                cy="16"
                r="15"
                :fill="messestandColorCopy"
              />
            </svg>
          </p>
        </div>

      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          size="sm"
          class="float-left"
          @click="cancel"
        >
          {{ $t('button.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="saveMessestandColor(ok)"
        >
          {{ $t('button.save') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal ref="messestandTextEditModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        {{ $t('page.profile.settings.descfp') }}
      </template>
      <div v-if="messestandTextCopy!==null" class="modal-body">

        <div
          class="mb-brand-desc"
        >
          <div class="mb-4 mt-4">
            <content-editor
              ref="selfSavingContentInstance2"
              v-model="messestandTextCopy"
            />
          </div>
        </div>

      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          size="sm"
          class="float-left"
          @click="cancel"
        >
          {{ $t('button.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="saveMessestandText(ok)"
        >
          {{ $t('button.save') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal ref="messestandTextUGEditModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        {{ $t('page.profile.settings.descug') }}
      </template>
      <div class="modal-body">

          <div
            class="mb-brand-desc"
          >
              <custom-editor
                v-model="rpsbrand.BusinessConcept"
                :small="true"
              />
          </div>

      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          size="sm"
          class="float-left"
          @click="cancel"
        >
          {{ $t('button.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="saveMessestandTextUG(ok)"
        >
          {{ $t('button.save') }}
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import ContentEditor from '../../../components/ContentEditor'
import CustomEditor from '../../../components/CustomEditor'
import ColorPicker from 'vue-color-picker-wheel'

export default {
  name: 'BrandProfileSettings',
  components: {
    ContentEditor,
    CustomEditor,
    ColorPicker
  },
  props: [],
  data: function () {
    return {
      views: {
        fpText: false,
        ugText: false
      },
      messestandColorCopy: null,
      messestandTextCopy: null
    }
  },
  computed: {
    computedLogoBarColor: {
      get: function () {
        return this.messestand.LogoBarColor
          ? String(this.messestand.LogoBarColor)
            .replace('FF', '#')
            .toUpperCase()
          : '#FFFFFF'
      },
      set: function (newValue) {
        if (newValue) {
          this.messestand.LogoBarColor = newValue
            .replace('#', 'FF')
            .toUpperCase()
        }
      }
    },
    logoBarColor: {
      get: function () {
        if (this.messestand.LogoBarColor) {
          return ['#', this.messestand.LogoBarColor].join('')
        }
        return '#FFFFFF'
      },
      set: function (newValue) {
        this.messestand.LogoBarColor = newValue.hex8
      }
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    preloads: function () {
      return this.app.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    rpsbrand: function () {
      return this.app.rpsBrand || {}
    }
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.saveMessestandDetails)
    },
    showMessestandColorEdit () {
      this.messestandColorCopy = this.computedLogoBarColor
      this.$refs.messestandColorEditModal.show()
    },
    saveMessestandColor (okModal) {
      this.computedLogoBarColor = String(this.messestandColorCopy)
      this.messestandColorCopy = null
      okModal()
      this.saveMessestandDetails()
    },
    showMessestandTextEdit () {
      this.messestandTextCopy = this.messestand.SystemPresentation
      this.$refs.messestandTextEditModal.show()
    },
    saveMessestandText (okModal) {
      this.$refs.selfSavingContentInstance2.saveContent().then(() => {
        this.messestandTextCopy = null
        okModal()
        this.showSaveSuccess()
      })
    },
    showMessestandTextUGEdit () {
      this.$refs.messestandTextUGEditModal.show()
    },
    saveMessestandTextUG (okModal) {
      this.saveUGDetails()
      okModal()
    },
    saveMessestandDetails () {
      const loader = this.app.startLoader()

      this.saveUGDetails(true)
      Promise.all([
        this.$refs.selfSavingContentInstanceUG.saveContent(),
        this.$refs.selfSavingContentInstance1.saveContent()
      ])
        .then(() => {
          const dataToSave = {
            FairID: this.messestand.FairID,
            EquityMandatory: this.messestand.EquityMandatory ? 1 : 0,
            LogoBarColor: this.messestand.LogoBarColor.replace('#', ''),
            SystemPresentation: this.messestand.SystemPresentation,
            Slogan: this.messestand.Slogan,
            FeaturedSnippet: this.messestand.FeaturedSnippet,
            FeaturedSnippetUG: this.messestand.FeaturedSnippetUG,
            UsedInSimilarSystems: this.messestand.UsedInSimilarSystems ? 1 : 0
          }

          this.app.API._put('fair/', dataToSave)
            .then(() => {
              this.showSaveSuccess()
            })
            .finally(loader.hide)
        })
    },
    showSaveSuccess () {
      this.app.unsavedChanges = []
      this.$swal(
        this.$t('popupmessage.success.savedtitle'),
        this.$t('popupmessage.success.fairchangessaved'),
        'success'
      )
    },
    saveUGDetails (silent = false) {
      let loader = null
      if (silent === false) {
        loader = this.app.startLoader()
      }

      const dataToSave = {
        RPSBrandID: this.rpsbrand.RPSBrandID,
        BusinessConcept: this.rpsbrand.BusinessConcept,
        Profile: this.rpsbrand.Profile
      }

      this.app.API._put('rpsbrand/', dataToSave)
        .then(() => {
          if (silent === false) {
            this.$swal(
              this.$t('popupmessage.success.savedtitle'),
              this.$t('popupmessage.success.ugbrandsaved'),
              'success'
            )
          }
        })
        .catch(console.error)
        .finally(() => {
          if (typeof loader !== 'undefined') {
            loader.hide()
          }
        })
    },
    openColorChanger () {
      this.$refs.colorSelectModal.show()
    }
  }
}
</script>

<style lang="css" scoped>
.logoBarColorSelected {
  border: 1px solid #eee;
}
.colorChanger {
  display: block;
  padding: 16px 0;
}
.vc-sketch {
  width: 320px !important;
}
.colorChanger {
  z-index: 1001;
}
.blueCard {
  background-color: #019fe0;
  border-radius: 10px;
  display: block;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 1rem;
}
.blueCard span {
  text-transform: uppercase;
  font-weight: bold;
}
.charCounterInsideContainer {
  position: relative;
}
.charCounterInside ~ input {
  padding-right: 15%;
}
.charCounterInside {
  position: absolute;
  right: 0;
  background-color: #EFEFEF;
  padding: 9px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 80%;
}
</style>
