<!-- component template -->
<template>
  <div>
    <custom-editor
      v-model="currentContent"
      :maxlength="maxlength"
      @change="$emit('change')"
    />
  </div>
</template>

<script>
// import Utils plugin
import Vue from 'vue'
import CustomEditor from './CustomEditor.vue'

Vue.use(CustomEditor)

// register the grid component
export default {
  name: 'ContentEditor',
  components: {
    CustomEditor
  },
  props: ['value', 'oldContent', 'maxlength'],
  data: function () {
    return {
      ContentID: null,
      currentContent: '',
      saving: false
      // cke_editor: ClassicEditor,
      // cke_config: {}
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    }
  },
  watch: {
    oldContent: function () {
      if (this.ContentID === null) {
        this.currentContent = this.oldContent ? this.oldContent : String()

        if (this.currentContent.length > 0) {
          this.saveContent()
        }
      }
    },
    value: function (newValue) {
      this.ContentID = newValue || 0
      this.loadContent()
    }
  },
  mounted () {
    this.ContentID = this.value > 0 ? this.value : false
    if (this.ContentID) {
      this.loadContent()
    } else {
      this.currentContent = ''
      if (
        typeof this.oldContent !== 'undefined' &&
        this.oldContent.length > 0
      ) {
        this.currentContent = this.oldContent
      }
      this.emitContentUpdate()
    }
  },
  methods: {
    startSaving () {
      this.saving = true
    },
    stopSaving () {
      this.saving = false
    },
    saveContent () {
      const contentToSave = String(this.currentContent)
      const saveParams = {
        ContentID: this.ContentID ? this.ContentID : -1,
        Body: contentToSave
      }

      return new Promise((resolve, reject) => {
        this.emitContentUpdate(contentToSave)
        this.startSaving()

        this.app
          .API._put('content/', saveParams)
          .then((response) => {
            if (response.body.Result) {
              if (typeof response.body.Result.NewID !== 'undefined') {
                const newID = response.body.Result.NewID

                if (newID > 0) {
                  this.updateValue(newID)
                } else {
                  this.updateValue(this.ContentID)
                  this.$emit('saveEnd')
                }
              } else {
                this.app.showSaveError()
              }
            }
            this.stopSaving()
            resolve()
          })
          .catch((error) => {
            console.error(error)
            this.app.showSaveError()
            reject(error)
          })
      })
    },
    updateValue (newValue) {
      this.$emit('input', newValue)
      this.$emit('saveEnd')
    },
    loadContent () {
      if (this.ContentID > 0) {
        this.app
          .API._post('contents/', {
            ResFrom: 1,
            ResTo: 1,
            FullSubEntities: false,
            filter: [
              {
                field: 'ContentID',
                operator: 'EQ',
                value: this.ContentID
              }
            ]
          })
          .then((response) => {
            const result = response.body.Result
            const data = result.Data

            const contentElement = data[0]

            const contentBody = contentElement.Body

            this.currentContent = String(contentBody)
            this.emitContentUpdate()
          })
      } else {
        this.currentContent = ''
      }
    },
    emitContentUpdate (content) {
      const newContent =
        typeof content === 'undefined' ? this.currentContent : content
      this.$emit('newContent', newContent)
    }
  }
}
</script>
