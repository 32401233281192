<template>

  <div class="d-flex align-items-center justify-content-between gap-3">

    <image-connected-selector
              v-model="currentBrand.LogoID"
              class="mb-edit-img"
              :default="require(`@/assets/images/brand-logo-edit.jpg`)"
              :edit-caption="$t('image.changelogo')"
              :ratio="29/20"
              :resolution-hint="'480 x 332 ' + $t('image.withouttransparency')"
              img-type="logo"
              @change="updateLogo"
              ref="sideLogo"
            />

      <a href="javascript:" class="mb-change-brand d-flex align-items-center justify-content-start gap-2"
        @click.prevent="clickBrandSelection">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.66667 15.8333L2 13.1667L4.66667 10.5" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M14 9.16669V10.5C14 11.2073 13.719 11.8855 13.219 12.3856C12.7189 12.8857 12.0406 13.1667 11.3333 13.1667H2"
            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11.3334 1.16669L14 3.83335L11.3334 6.50002" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M2 7.83331V6.49998C2 5.79274 2.28095 5.11446 2.78105 4.61436C3.28115 4.11426 3.95942 3.83331 4.66667 3.83331H14"
            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="lh-sm">{{ $t("menue.changebrand") }}</span>
      </a>
  </div>
</template>

<script>
import Config from '@/config/index'
import { useBrandStore } from '@/stores/brandStore'
import ImageConnectedSelector from '@/components/ImageConnectedSelector'

export default {
  name: 'ChangeBrandComponent',
  data: function () {
    return {
      brandStore: useBrandStore()
    }
  },
  components: {
    ImageConnectedSelector
  },
  inject: ['changeBrand', 'closeSidebar'],
  props: ['currentBrand'],
  methods: {
    clickBrandSelection () {
      this.changeBrand(false).then(() => {
        this.$router.push('/markenauswahl').catch(() => { })
        this.closeSidebar()
      })
    },
    registerChange () {
      console.log('registerChange')
    },
    updateLogo () {
      this.app.saveBrand()
    }
  },
  computed: {
    app: function () {
      return this.$root.getApp() || {}
    },
    computedBrandLogoPath () {
      if (!this.brandStore.getCurrentBrand) return null
      if (!this.brandStore.getCurrentBrand.Logo) return null
      return `${Config.MEDIA_API_URL}id/${this.brandStore.getCurrentBrand.Logo.FileID}?size=sm&format=webp`
    }
  }
}

</script>
