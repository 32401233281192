<template>
  <div>
    <div
      id="videos"
      class="container mb-marke-videos mt-5 p-0"
    >
      <h5 class="mb-card-title">
        {{ $t('page.profile.video.title', { count : fairVideos.length }) }}
      </h5>
      <div class="card mb-5">
        <div class="card-body">
          <form action="?page=markenprofil">
            <p
              v-if="fairVideos.length===0"
              class="d-block"
            >
              {{ $t('page.profile.video.novideos') }}
            </p>
            <div class="mb-tile-list" style="overflow-x: auto">
              <Container @drop="onDrop" drag-handle-selector=".column-drag-handle" v-if="fairVideos.length > 0" orientation="horizontal" behaviour="contain">
                  <Draggable v-for="video in fairVideos" :key="video.FairVideoID" class="card mb-tile">
                      <div class="draggable-item-horizontal">
                        <div class="mb-edit-img">
                          <div class="column-drag-handle" style="padding:0 10px;" v-html="$t('component.move')"></div>
                          <image-connected-selector
                            :ref="`fairVideoSelector_${video.FairVideoID}`"
                            v-model="video.Medium.StillImageID"
                            class="mb-edit-img"
                            @change="registerChange"
                          />
                        </div>
                        <div class="card-body">
                          <input
                            v-model="video.Medium.Title"
                            type="text"
                            class="form-control"
                            name="video1"
                            placeholder="Video Titel"
                            @input="registerChange"
                          >
                          <div class="mt-3">
                            <a
                              :href="getViewLink(video)"
                              class="card-link mb-play-video"
                              target="_blank"
                            >{{ $t('page.profile.video.watch') }}</a>
                            <a
                              href="javascript:"
                              class="card-link text-danger"
                              @click.prevent="deleteVideo(video)"
                            >{{ $t('page.profile.video.delete') }}</a>
                          </div>
                        </div>
                      </div>
                  </Draggable>
              </Container>
            </div>
            <a
              :href="addVideoLink"
              class="btn btn-primary"
              target="_blank"
            >
              <span>+</span>
              {{ $t('page.profile.video.addnew') }}
            </a>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '../../../config'
import ImageConnectedSelector from '../../../components/ImageConnectedSelector'
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from '../../../helpers/dndhelper'

export default {
  name: 'BrandProfileVideos',
  components: {
    ImageConnectedSelector,
    Container,
    Draggable
  },
  props: [],
  data: function () {
    return {}
  },
  mounted: function () {
    if (this.messestand != null && this.messestand.FairVideos != null && this.messestand.FairVideos.length > 0) {
      this.messestand.FairVideos = this.messestand.FairVideos.filter((video) => {
        return video.Medium.Filename !== 'youtube-wdw' || video.Medium.FileID > 0
      })
    }
  },
  computed: {
    fairVideos: function () {
      if (!this.messestand) return []
      if (!this.messestand.FairVideos) return []
      return this.messestand.FairVideos
    },
    imageDriveURL () {
      return Config.MEDIA_API_URL
    },
    app: function () {
      return this.$parent.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    addVideoLink: function () {
      return 'https://www.franchiseuniversum.de/videoupload-messestand/?brandid=' + this.currentBrand.BrandID
    }
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.saveFairVideos)
    },
    getViewLink (video) {
      if (video.Medium.FileID > 0) {
        return [this.imageDriveURL, 'id/', video.Medium.FileID].join('')
      }

      if (video.Medium.Filename === 'youtube') {
        return `https://www.youtube.com/watch?v=${video.Medium.URL}`
      }

      return false
    },
    saveFairVideos () {
      const loader = this.app.startLoader()

      Promise.all(
        this.messestand.FairVideos.filter(fairvideo => {
          return !!fairvideo.MediumID
        }).map(fairvideo => {
          return new Promise((resolve, reject) => {
            const mediumToSave = {
              MediumID: fairvideo.MediumID,
              StillImageID: fairvideo.Medium.StillImageID,
              Title: fairvideo.Medium.Title
            }

            if (!(mediumToSave.StillImageID > 0)) {
              delete mediumToSave.StillImageID
              mediumToSave.NullFields = ['StillImageID']
            }

            this.app.API._put('medium/', mediumToSave)
              .then(() => {
                this.app.unsavedChanges = []
                resolve()
              })
              .catch(reject)
          })
        })
      )
        .then(
          this.$swal(
            this.$t('popupmessage.success.savedtitle'),
            this.$t('popupmessage.success.videossaved'),
            'success'
          )
        )
        .catch(() => {
          this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.unknown'), 'error')
        })
        .finally(loader.hide)
    },
    deleteVideo (videoToDelete) {
      this.$swal({
        title: this.$t('popupmessage.areyousuretitle'),
        text: this.$t('popupmessage.videodeletemessage'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(result => {
        if (typeof result.value !== 'undefined') {
          this.deleteVideoFromApi(videoToDelete)
        }
      })
    },
    deleteVideoFromApi (videoToDelete) {
      this.app.API._delete(
        ['fairvideo', '/', videoToDelete.FairVideoID].join('')
      ).then(() => {
        this.messestand.FairVideos = this.messestand.FairVideos.filter(
          video => {
            return videoToDelete.FairVideoID !== video.FairVideoID
          }
        )
      })
    },
    onDrop (dropResult) {
      this.messestand.FairVideos = applyDrag(this.messestand.FairVideos, dropResult)
      this.handleSortOrderChange()
      this.$toast.success(this.$t('component.changedpossaved'))
    },
    handleSortOrderChange () {
      for (let i = 0; i < this.messestand.FairVideos.length; i++) {
        const elm = this.messestand.FairVideos[i]
        this.app.API._put('fairvideo', {
          FairVideoID: elm.FairVideoID,
          SortPos: i
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.card-body {
  min-width: 320px;
}
</style>
