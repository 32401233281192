// stores/counter.js
import { defineStore } from 'pinia'
import ApiStripe from '@/modules/Api_Stripe'

const _ApiStripe = new ApiStripe()

export const useBrandStore = defineStore('brand', {
  state: () => {
    return {
      currentBrand: null,
      currentBrandID: null,
      isLoadingBrand: false,
      currentMembership: null,
      isLoadingMembership: false,
      currentLeads: [],
      markedContacts: [],
      leadsCount: 0
    }
  },
  getters: {
    getCurrentBrand () {
      return this.currentBrand
    },
    getCurrentBrandID () {
      return this.currentBrandID
    },
    getCurrentMembership () {
      return this.currentMembership
    },
    getCurrentLeads () {
      // make unique by LeadID
      return this.currentLeads.filter((v, i, a) => a.findIndex(t => (t.LeadID === v.LeadID)) === i)
    },
    getLoadingBrand () {
      return this.isLoadingBrand
    },
    getLoadingMembership () {
      return this.isLoadingMembership
    },
    getCurrentLeadsCount () {
      return this.leadsCount
    },
    getMarkedContacts () {
      return this.markedContacts
    }
  },
  actions: {
    setCurrentBrand (brand) {
      if (brand === null) {
        this.currentBrandID = null
        this.currentBrand = null
        this.currentMembership = null
        this.currentLeads = []
        this.leadsCount = 0
        return
      }
      this.currentBrand = brand
      this.currentBrandID = brand.BrandID
      this.loadCurrentMembership()
    },
    setCurrentBrandID (brandID) {
      this.currentBrandID = brandID
    },
    setCurrentMembership (membership) {
      this.currentMembership = membership
    },
    async loadCurrentMembership () {
      this.isLoadingMembership = true
      const result = await _ApiStripe._post(
        'getBrandMembershipValues', {
          BrandID: this.getCurrentBrandID
        }
      )
      this.currentMembership = result.body
      this.isLoadingMembership = false
    },
    setCurrentLeads (leads) {
      this.currentLeads = leads
    },
    addCurrentLeads (leads) {
      this.currentLeads = this.currentLeads.concat(leads)
    },
    clearCurrentLeads () {
      this.currentLeads = []
      this.setCurrentLeadsCount(0)
    },
    setCurrentLeadsCount (total) {
      this.leadsCount = total
    },
    markContact (ContactID) {
      this.markedContacts.push(ContactID)
    },
    unmarkContact (ContactID) {
      const index = this.markedContacts.indexOf(ContactID)
      if (index > -1) {
        this.markedContacts.splice(index, 1)
      }
    }
  }
})
