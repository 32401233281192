// stores/counter.js
import { defineStore } from 'pinia'

export const usePageStore = defineStore('page', {
  state: () => {
    return {
      runningLoaders: [],
      appClassess: []
    }
  },
  getters: {
    getLoadingState () {
      return this.runningLoaders.length > 0
    },
    getComputedAppClasses () {
      return this.appClassess.join(' ')
    }
  },
  actions: {
    startLoading (loaderName) {
      this.runningLoaders.push(loaderName)
    },
    stopLoading (loaderName) {
      const index = this.runningLoaders.indexOf(loaderName)
      if (index >= 0) {
        this.runningLoaders.splice(index, 1)
      }
    },
    isLoading (loaderName) {
      return this.runningLoaders.indexOf(loaderName) >= 0
    }
  }
})
