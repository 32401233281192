import { required } from 'vee-validate/dist/rules';
<template>
  <div class="col-sm mb-copyright">
    <h6>{{ $t('copyright.title') }}</h6>
    <input
      :id="imageCopyrightId"
      v-model="isSameAsBrandName"
      type="radio"
      :name="imageCopyrightId"
      :value="true"
      @change="triggerSaveBrandName"
    >
    <label :for="imageCopyrightId">{{ currentBrand.Name }}</label>
    <div class="d-flex">
      <input
        v-model="isSameAsBrandName"
        type="radio"
        :name="imageCopyrightId"
        :value="false"
        @change="triggerSave"
      >
      <input
        v-model="image.Copyright"
        type="text"
        class="form-control"
        :class="customErrorClass"
        @focus="changeRadioInputOnFocus"
        @blur="triggerSave"
        @input="triggerCheck"
        @change="triggerCheck"
        ref="customCopyright"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyrightInput',
  components: {},
  props: ['name', 'image'],
  data: function () {
    return {
      isSameAsBrandNamee: false,
      customCopyright: '',
      init: false,
      customErrorClass: ''
    }
  },
  computed: {
    imageCopyrightId: function () {
      return `imageCopyright_${this.name}`
    },
    app: function () {
      return this.$root.getApp() || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    preloads: function () {
      return this.app.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    },
    isSameAsBrandName: {
      get: function () {
        return this.image.Copyright == null || this.image.Copyright.trim() === ''
      },
      set: function (newValue) {
      }
    }
  },
  watch: {
    isSameAsBrandName: function (newValue) {
      this.triggerSave(newValue)
    }
  },
  mounted: function () {
    this.customCopyright = this.image.Copyright
  },
  methods: {
    triggerSave (newValue) {
      if (this.image.Copyright) {
        this.saveCopyright(this.image.Copyright)
      }
    },
    triggerSaveBrandName () {
      this.saveCopyright(this.currentBrand.Name)
    },
    triggerCheck () {
      if (this.isSameAsBrandName) {
        this.customErrorClass = ''
        return
      }

      const srcElm = this.$refs.customCopyright
      if (srcElm.value == null || srcElm.value === '') {
        this.customErrorClass = 'mb-field-error'
      } else {
        this.customErrorClass = ''
      }
    },
    saveCopyright (copyrightString) {
      if (copyrightString == null || copyrightString === '') return false
      const dataToSave = {
        MediumID: this.image.MediumID,
        Copyright: copyrightString
      }

      if (!(dataToSave.MediumID > 0)) return false

      if (this.isSameAsBrandName || copyrightString === this.currentBrand.Name) {
        delete dataToSave.Copyright
        dataToSave.NullFields = ['Copyright']
      }

      this.app.API._put('medium/', dataToSave)
        .then((response) => {
          if (!this.$parent.$parent.$parent.isDropAction) {
            this.$toast.success(this.$t('copyright.saved'))
          }
        })
    },
    changeRadioInputOnFocus () {
      this.image.Copyright = ''
    }
  }
}
</script>

<style lang="scss">
</style>
