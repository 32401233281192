<template>
  <div>
    <div
      v-if="currentBrand && isMembershipLoaded"
      id="grundeinstellungen"
      class="mb-marke-basic"
    >
      <div class="card bg-white">
        <div class="card-body">
          <div
            v-if="currentBrand.BrandID"
            class="row"
          >
            <div class="col-8 border-right">
              <h5 class="card-title">
                {{ $t('page.dashboard.title') }}
              </h5>
              <form
                method="POST"
                action="javascript:"
              >
                <div class="row">
                  <div class="col">
                    <div class="mb-list">
                      <label>{{ $t('page.dashboard.name') }}</label>
                      <input
                        v-model="currentBrand.Name"
                        type="text"
                        name="name"
                        class="form-control"
                        required
                        @change="registerChange"
                      >
                    </div>
                    <div class="mb-list">
                      <label>{{ $t('page.dashboard.website') }}</label>
                      <input
                        v-model="currentBrand.Homepage"
                        type="url"
                        name="website"
                        class="form-control"
                        required
                        @change="registerChange"
                      >
                    </div>
                  </div>
                </div>
                <div v-if="app.preloads.mainsectors.length" class="mb-list mb-sectors">
                  <label>{{ $t('page.dashboard.mainsectors') }}</label>
                  <custom-multiselect v-model="currentBrand.MainSectorIDs" :options="app.preloads.mainsectors" label="Caption" identifier="MainSectorID" :multiple="true" @change="registerChange" />
                </div>
                <div v-if="app.preloads.sectors.length" class="mb-list mb-sectors">
                  <label>{{ $t('page.dashboard.sectors') }}</label>
                  <custom-multiselect v-model="currentBrand.SectorIDs" :options="app.preloads.sectors" label="Caption" identifier="SectorID" :multiple="true" @change="registerChange" />
                </div>
              </form>
              <div class="mb-list mb-pub-urls">
                <label v-t="'page.dashboard.publications'"></label>
                <div>
                  <div
                    v-for="url in getFPUrls" :key="url"
                    class="mb-2"
                  >
                    <div class="mb-lst-url">
                      <input type="text" readonly :value="url">
                      <span>
                        <a :href="url" target="_blank"><i class="fa fa-external-link"></i></a>
                        <i class="fa fa-clone" @click="copyUrl(url)"></i>
                      </span>
                    </div>
                  </div>
                  <div
                    class="mb-list"
                    v-if="currentMembership&&currentMembership.Memberships.DE"
                  >
                    <div class="mb-lst-url">
                      <input type="text" readonly :value="getUGUrl()">
                      <span>
                        <a :href="getUGUrl()" target="_blank"><i class="fa fa-external-link"></i></a>
                        <i class="fa fa-clone" @click="copyUrl(getUGUrl())"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <h5 class="card-title">
                {{ $t('company.title') }}
              </h5>
              <div
                v-if="currentBrand.Company"
                class="mb-brand-address"
              >
                <router-link
                  class="mb-edit-company"
                  to="/adresse-aendern"
                >
                  <i class="fa fa-pencil"></i>{{ $t('company.change') }}
                </router-link>

                <h6>{{ currentBrand.Company.Name }}</h6>
                <div>{{ currentBrand.Company.Street }}</div>
                <div>{{ currentBrand.Company.Zip }} {{ currentBrand.Company.City }}</div>
                <div>---</div>
                <div>{{ currentBrand.Company.Phone }}</div>
                <a
                  :href="'mailto:'+currentBrand.Company.Mail"
                  class="card-link"
                >{{ currentBrand.Company.Mail }}</a>
              </div>
              <div
                v-else
                class="text-center mb-3"
              >
                {{ $t('company.nocompany') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomMultiselect from '@/components/CustomMultiselect'
import Vue from 'vue'
import Config from '@/config'

export default {
  name: 'BrandProfileDefault',
  components: {
    CustomMultiselect
  },
  props: [],
  data: function () {
    return {
      websiteUrl: Vue.Utils.trim(Config.WEBSITE_URL, '/') + '/',
      fpUrls: [],
      savedLangClass: ''
    }
  },
  mounted () {
  },
  computed: {
    app: function () {
      return this.$parent.$parent || {}
    },
    currentBrand: function () {
      return this.app.currentBrand || {}
    },
    preloads: function () {
      return this.app.preloads || {}
    },
    currentMembership: function () {
      return this.app.currentMembership || {}
    },
    isMembershipLoaded: function () {
      return typeof this.currentMembership.Memberships !== 'undefined'
    },
    getFPUrls: function () {
      this.setFPUrls()

      return this.fpUrls
    }
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    },
    setFPUrls () {
      this.fpUrls = []
      const website = this.websiteUrl + 'franchise-unternehmen/' + this.app.currentBrandSlug
      if (this.currentBrand.Membership === 3) {
        if (this.currentMembership.Memberships.DE) this.fpUrls.push(website)
        if (this.currentMembership.Memberships.AT) this.fpUrls.push(website.replace('.de', '.at'))
        if (this.currentMembership.Memberships.CH) this.fpUrls.push(website.replace('.de', '.ch'))
      } else if (this.currentBrand.Membership === 2) {
        if (this.currentBrand.ComParamOriginCountryID !== 2 && this.currentBrand.ComParamOriginCountryID !== 5) this.fpUrls.push(website)
        if (this.currentBrand.ComParamOriginCountryID === 2) this.fpUrls.push(website.replace('.de', '.at'))
        if (this.currentBrand.ComParamOriginCountryID === 5) this.fpUrls.push(website.replace('.de', '.ch'))
      } else {
        this.fpUrls.push(website)
      }
    },
    getUGUrl () {
      return 'https://www.unternehmer-gesucht.com/franchise-unternehmen/' + this.app.currentBrandSlug + '/'
    },
    copyUrl (url) {
      navigator.clipboard.writeText(url)
      this.$toast.success(this.$t('page.dashboard.urlcopy'))
    }
  },
  watch: {
    'currentBrand.SectorIDs': function (newVal, oldVal) {
      if (this.currentBrand.SectorIDs && newVal && newVal.length > 3) {
        this.currentBrand.SectorIDs = newVal.slice(0, 3)
        this.$swal('Limit', 'Es sind maximal drei Branchen zulässig.', 'warning')
      }
    },
    'currentBrand.MainSectorIDs': function (newVal, oldVal) {
      if (this.currentBrand.MainSectorIDs && newVal && newVal.length > 2) {
        this.currentBrand.MainSectorIDs = newVal.slice(0, 2)
        this.$swal('Limit', 'Es sind maximal zwei Hauptbranchen zulässig.', 'warning')
      }
    },
    'app.currentMembership.Memberships': function () {
      this.setFPUrls()
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-marke-basic .mb-list label {
  min-width: 210px !important;
}
.mb-list {
  position: relative;
}
</style>
