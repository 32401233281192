import Config from '../config'

const getImagePathFromMedium = Medium => {
  if (Medium.FileID > 0) {
    return [Config.MEDIA_API_URL, 'id/', Medium.FileID].join('')
  }
  return '#invalid'
}

export default {
  getImagePathFromMedium
}
