<template>
  <div>
    <div v-if="app.hasCurrentBrand" class="mb-main-content">
      <h1 v-t="'page.contacts.title'"></h1>
      <div class="mb-5" v-html="$t('page.contacts.description')"></div>

      <div class="mb-kontakte">
        <div class="row">
          <div class="col-5 mb-kontakte-mitarbeiter">
            <h2>
              {{ $t("page.contacts.fiststep") }}
              <span class="icon-pfeilrechts" />
            </h2>
            <div class="card shadow p-3 mb-5 bg-white">
              <div class="card-body">
                <h5 class="card-title mt-0 pb-3 border-bottom">
                  {{
                    $t("page.contacts.employeesofcompany", {
                      company: currentBrand.Company.Name,
                    })
                  }}
                </h5>

                <div v-if="companyContacts">
                  <p
                    v-for="companyContact in companyContacts"
                    :key="companyContact.ContactCompanyRoleID"
                    class="mb-contact-data pb-3 border-bottom"
                  >
                    <label class="font-weight-bold">{{
                      companyContact.CompanyRole.Caption
                    }}</label>
                    <label class="font-weight-bold"
                      >{{ companyContact.Contact.Salutation.Caption }}
                      {{ companyContact.Contact.EntireTitle }}
                      {{ companyContact.Contact.Firstname }}
                      {{ companyContact.Contact.Lastname }}</label
                    >
                    <label v-if="companyContact.Contact.Phone"
                      >{{ $t("page.contacts.phone") }}
                      {{ companyContact.Contact.IntPhonePrefix }}
                      {{ companyContact.Contact.Phone }}</label
                    >
                    <label
                      v-if="
                        companyContact.Contact.Mobile &&
                        companyContact.Contact.Mobile !== ' '
                      "
                      >{{ $t("page.contacts.mobile") }}
                      {{ companyContact.Contact.IntMobilePrefix }}
                      {{ companyContact.Contact.Mobile }}</label
                    >
                    <label
                      >{{ $t("page.contacts.mail") }}
                      {{ companyContact.Contact.Mail }}</label
                    >
                    <a
                      href="javascript:"
                      class="card-link mt-2 d-inline"
                      @click.prevent="editCompanyContact(companyContact)"
                      v-t="'page.contacts.editcontact'"
                    ></a>
                    <a
                      href="javascript:"
                      class="card-link text-danger"
                      target="_blank"
                      @click.prevent="openRemoveNotice(companyContact)"
                      v-t="'page.contacts.deletecontact'"
                    ></a>
                  </p>
                </div>
                <p v-else v-t="'page.contacts.noemployees'"></p>

                <div class="form-group pt-4">
                  <button
                    class="btn btn-secondary"
                    @click.prevent="addContact"
                    v-t="'page.contacts.addcontact'"
                  ></button>
                </div>
              </div>
              <div class="card-body border-top">
                <h5 class="card-title pb-3 border-bottom">
                  <div v-t="'page.contacts.external'"></div>
                  {{ currentBrand.Name }}
                </h5>
                <p v-t="'page.contacts.externaldesc'"></p>
                <div v-if="externalBrandContacts.length">
                  <p
                    v-for="brandContact in externalBrandContacts"
                    :key="brandContact.ContactCompanyRoleID"
                    class="mb-contact-data pb-3 border-bottom"
                  >
                    <label class="font-weight-bold"
                      >{{ brandContact.Contact.Salutation.Caption }}
                      {{ brandContact.Contact.EntireTitle }}
                      {{ brandContact.Contact.Firstname }}
                      {{ brandContact.Contact.Lastname }}</label
                    >
                    <label v-if="brandContact.Contact.Phone"
                      >{{ $t("page.contacts.phone") }}
                      {{ brandContact.Contact.IntPhonePrefix }}
                      {{ brandContact.Contact.Phone }}</label
                    >
                    <label
                      v-if="
                        brandContact.Contact.Mobile &&
                        brandContact.Contact.Mobile !== ' '
                      "
                      >{{ $t("page.contacts.mobile") }}
                      {{ brandContact.Contact.IntMobilePrefix }}
                      {{ brandContact.Contact.Mobile }}</label
                    >
                    <label
                      >{{ $t("page.contacts.mail") }}
                      {{ brandContact.Contact.Mail }}</label
                    >
                    <a
                      :href="removeExternalBrandManagerFormLink"
                      class="card-link mt-2 d-block text-danger"
                      target="_blank"
                      v-t="'page.contacts.deletecontact'"
                    ></a>
                  </p>
                </div>
                <p v-else v-t="'page.contacts.noexternal'"></p>
                <div class="form-group pt-3">
                  <a
                    class="btn btn-secondary"
                    target="_blank"
                    :href="addExternalBrandManagerFormLink"
                    v-t="'page.contacts.addexternal'"
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-7 mb-kontakte-funktionen">
            <h2 v-t="'page.contacts.secondstep'"></h2>
            <div class="card shadow p-3 mb-5 bg-white">
              <div class="card-body">
                <form>
                  <h5
                    class="card-title mt-0 pb-3 border-bottom"
                    v-t="'page.contacts.functionsforfpandinterests'"
                  ></h5>
                  <h6
                    class="card-title"
                    v-t="'page.contacts.contactforfp'"
                  ></h6>
                  <label
                    class="card-subtitle mb-4 small text-muted"
                    v-t="'page.contacts.contactforfpdesc'"
                  ></label>
                  <div class="form-group">
                    <select
                      v-model="currentBrand.PrimaryContactID"
                      type="text"
                      name="kontakt"
                      class="form-control"
                      required
                      @change="changePrimaryContactID"
                    >
                      <option
                        disabled
                        selected
                        value="null"
                        v-t="'choice.pleaseselect'"
                      ></option>
                      <option
                        v-for="companyContact in ContactsFP.primaerkontakt.list"
                        :key="companyContact.CompanyContactRoleID"
                        :value="companyContact.Contact.ContactID"
                      >
                        {{ companyContact.Contact.Salutation.Caption }}
                        {{ companyContact.Contact.EntireTitle }}
                        {{ companyContact.Contact.Firstname }}
                        {{ companyContact.Contact.Lastname }}
                        {{ showCompanyNameIfAvailable(companyContact) }}
                      </option>
                    </select>
                  </div>
                  <h6
                    class="card-title border-top pt-3 mt-4"
                    v-t="'page.contacts.contactforinterests'"
                  ></h6>
                  <label
                    class="card-subtitle mb-4 small text-muted"
                    v-t="'page.contacts.contactforinterestsdesc'"
                  ></label>
                  <div class="row">
                    <div v-if="currentBrand.InterestedContactID" class="col-3">
                      <image-connected-selector
                        v-model="computedContactPortraitID"
                        class="mb-edit-img"
                        :default="defaultImage"
                        :save="saveImageToContact"
                        :ratio="1"
                        editClass="silhouette-portrait"
                        :resolution-hint="$t('image.recoprofilepicture')"
                      />
                    </div>
                    <div :class="classInterestedContactSelectForm">
                      <div class="form-group">
                        <select
                          v-model="currentBrand.InterestedContactID"
                          type="text"
                          name="kontakt"
                          class="form-control"
                          required
                          @change="changeInterestedContact"
                        >
                          <option
                            disabled
                            selected
                            value="null"
                            v-t="'choice.pleaseselect'"
                          ></option>
                          <option
                            v-for="companyContact in ContactsFP.interessenten
                              .list"
                            :key="companyContact.CompanyContactRoleID"
                            :value="companyContact.Contact.ContactID"
                            :checked="
                              companyContact.Contact.ContactID ===
                              currentBrand.InterestedContactID
                            "
                          >
                            {{ companyContact.Contact.Salutation.Caption }}
                            {{ companyContact.Contact.EntireTitle }}
                            {{ companyContact.Contact.Firstname }}
                            {{ companyContact.Contact.Lastname }}
                            {{ showCompanyNameIfAvailable(companyContact) }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <span class="icon-brief" />
                          <input
                            v-model="currentBrand.InterestedMail"
                            type="email"
                            class="form-control ps-5"
                            :placeholder="$t('page.contacts.specialemail')"
                            @change="registerChange"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <h6
                    class="card-title border-top pt-3 mt-4"
                    v-t="'page.contacts.emailforcontactreq'"
                  ></h6>
                  <label
                    class="card-subtitle mb-4 small text-muted"
                    v-html="$t('page.contacts.emailforcontactreqdesc')"
                  ></label>
                  <div class="form-group">
                    <div class="input-group">
                      <span class="icon-brief" />
                      <input
                        v-model="currentBrand.InquiryMail"
                        type="text"
                        class="form-control ps-5"
                        :placeholder="$t('page.contacts.contactemailadress')"
                        @change="registerChange"
                      />
                    </div>
                  </div>

                  <h6
                    class="card-title border-top pt-3 mt-4"
                    v-t="'page.contacts.leadapimail'"
                  ></h6>
                  <label
                    class="card-subtitle mb-4 small text-muted"
                    v-t="'page.contacts.leadapimaildesc'"
                  ></label>
                  <div class="form-group">
                    <div class="input-group">
                      <span class="icon-brief" />
                      <input
                        v-model="currentBrand.MailExternalInterface"
                        type="email"
                        class="form-control ps-5"
                        :placeholder="$t('page.contacts.apimail')"
                        @change="registerChange"
                      />
                    </div>
                  </div>

                  <h6
                    class="card-title border-top pt-3 mt-4"
                    v-t="'page.contacts.logins'"
                  ></h6>
                  <label
                    class="card-subtitle mb-4 small text-muted"
                    v-t="'page.contacts.loginsdesc'"
                  ></label>
                  <div class="mb-4">
                    <ul
                      v-if="currentMarkenverwalter"
                      class="markenverwalterListe"
                    >
                      <li
                        v-for="companyContact in currentMarkenverwalter"
                        :key="companyContact.CompanyContactRoleID"
                      >
                        <span v-if="companyContact.Contact.Firstname">
                          {{ companyContact.Contact.Salutation.Caption }}
                          {{ companyContact.Contact.EntireTitle }}
                          {{ companyContact.Contact.Firstname }}
                          {{ companyContact.Contact.Lastname }}
                          {{ showCompanyNameIfAvailable(companyContact) }}
                          <br />
                        </span>
                        {{ companyContact.Contact.Mail }}
                        <br />
                        <a
                          :href="removeLoginFormLink"
                          class="card-link text-danger"
                          target="_blank"
                          v-t="'page.contacts.deletelogin'"
                        ></a>
                      </li>
                    </ul>
                  </div>

                  <div v-show="companyContactsWithoutLogin.length">
                    <div class="mb-4">
                      <div class="form-group">
                        <select
                          v-model="newBrandManagerSelect"
                          type="text"
                          name="kontakt"
                          class="form-control"
                        >
                          <option
                            disabled
                            selected
                            value="0"
                            v-t="'page.contacts.selectlogincontact'"
                          ></option>
                          <option
                            v-for="companyContact in companyContactsWithoutLogin"
                            :key="companyContact.CompanyContactRoleID"
                            :value="companyContact.Contact.ContactID"
                          >
                            #{{ companyContact.ContactID }}
                            {{ companyContact.Contact.Salutation.Caption }}
                            {{ companyContact.Contact.EntireTitle }}
                            {{ companyContact.Contact.Firstname }}
                            {{ companyContact.Contact.Lastname }},
                            {{ companyContact.Contact.Mail }} ({{
                              showCompanyNameIfAvailable(companyContact)
                            }})
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="pb-4 text-right">
                      <button
                        class="btn btn-secondary"
                        @click.prevent="addBrandManager"
                        v-t="'page.contacts.createlogin'"
                      ></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="contactEditModal" no-close-on-backdrop>
      <template v-slot:modal-title>
        {{ computedFormTitle }}
      </template>
      <div class="modal-body">
        <form id="editContactForm">
          <div v-if="0" class="custom-file form-group mb-3">
            <input id="customFile" type="file" class="custom-file-input" />
            <label
              class="custom-file-label"
              for="customFile"
              v-t="'page.contacts.selectportrairphoto'"
            ></label>
          </div>

          <div class="form-group">
            <select
              v-model="currentContactFormData.SalutationID"
              type="text"
              name="anrede"
              class="form-control"
              required
            >
              <option
                disabled
                selected
                :value="0"
                v-t="'page.contacts.salutation'"
              ></option>
              <option
                v-for="salutation in preloads.salutations"
                :key="salutation.SalutationID"
                :value="salutation.SalutationID"
              >
                {{ salutation.Caption }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <select
              v-model="currentContactFormData.TitleID"
              type="text"
              name="titel"
              class="form-control"
            >
              <option
                disabled
                selected
                :value="0"
                v-t="'page.contacts.contacttitle'"
              ></option>
              <option
                v-for="title in preloads.titles"
                :key="title.TitleID"
                :value="title.TitleID"
              >
                {{ title.Caption }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <input
              v-model="currentContactFormData.Firstname"
              type="text"
              name="vorname"
              class="form-control"
              :placeholder="$t('page.contacts.firstname')"
              required
            />
          </div>
          <div class="form-group">
            <input
              v-model="currentContactFormData.Lastname"
              type="text"
              name="nachname"
              class="form-control"
              :placeholder="$t('page.contacts.lastname')"
              required
            />
          </div>
          <div class="form-group">
            <input
              v-model="currentContactFormData.Phone"
              type="text"
              name="telefon"
              class="form-control"
              :placeholder="$t('address.phone')"
            />
          </div>
          <div class="form-group">
            <input
              v-model="currentContactFormData.Mobile"
              type="text"
              name="mobil"
              class="form-control"
              :placeholder="$t('address.mobile')"
            />
          </div>
          <div class="form-group">
            <input
              v-model="currentContactFormData.Mail"
              type="email"
              name="email"
              class="form-control"
              :placeholder="$t('page.contacts.mail')"
              required
            />
          </div>
          <div class="form-group">
            <select
              v-model="currentContactFormData.CompanyRoleID"
              type="text"
              name="function"
              class="form-control"
              required
            >
              <option
                disabled
                selected
                :value="0"
                v-t="'page.contacts.function'"
              ></option>
              <option
                v-for="companyrole in preloads.companyroles"
                :key="companyrole.CompanyRoleID"
                :value="companyrole.CompanyRoleID"
              >
                {{ companyrole.Caption }}
              </option>
            </select>
          </div>
        </form>
      </div>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          size="sm"
          class="float-left"
          @click="cancelEdit(cancel)"
        >
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="saveCompanyContact(ok)"
        >
          {{ $t("button.save") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Config from '../../config'
import axios from 'axios'
import ImageConnectedSelector from '../../components/ImageConnectedSelector'

import { usePageStore } from '@/stores/pageStore'

function generatePassword (length) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let password = ''
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length)
    password += chars.charAt(randomIndex)
  }
  // Ensure at least one number in the password
  if (!/\d/.test(password)) {
    const replaceAt = Math.floor(Math.random() * password.length)
    const num = Math.floor(Math.random() * 10)
    password = password.substr(0, replaceAt) + num + password.substr(replaceAt + 1)
  }
  return password
}

const emptyContactFormData = {
  ContactCompanyRoleID: -1,
  ContactID: -1,
  CompanyID: -1,
  CompanyRoleID: 0,
  SalutationID: 0,
  TitleID: 0,
  Firstname: '',
  Lastname: '',
  Phone: '',
  Mobile: '',
  Mail: ''
}
export default {
  name: 'Contacts',
  components: {
    ImageConnectedSelector
  },
  props: [],
  data: function () {
    return {
      companyContacts: [],
      brandContacts: [],
      currentContactFormData: emptyContactFormData,
      contactMailBeforeSave: null,
      logoutAfterUsernameChange: false,
      showContactAddForm: false,
      companyLoadedID: null,
      newBrandManagerSelect: 0,
      allContacts: [],
      ContactsFP: {
        primaerkontakt: {
          roleId: 5,
          headline: this.$t('page.contacts.contactforfp'),
          list: []
        },
        interessenten: {
          roleId: 2,
          headline: this.$t('page.contacts.contactforinterests'),
          list: []
        }
      },
      pageStore: usePageStore()
    }
  },
  computed: {
    computedFormTitle () {
      return this.currentContactFormData.ContactCompanyRoleID > 0
        ? this.$t('page.contacts.changeemployee')
        : this.$t('page.contacts.addemployee')
    },
    classInterestedContactSelectForm () {
      if (this.currentBrand.InterestedContactID > 0) {
        return 'col-9'
      }
      return 'col-12'
    },
    computedContactPortraitID: {
      get: function () {
        if (this.currentBrand.InterestedContact === null) return null
        if (typeof this.currentBrand.InterestedContact !== 'object') {
          return null
        }
        if (
          typeof this.currentBrand.InterestedContact.PortraitID === 'undefined'
        ) {
          return null
        }
        return this.currentBrand.InterestedContact.PortraitID
      },
      set: function (newValue) {
        this.currentBrand.InterestedContact =
          this.currentBrand.InterestedContact || {}
        this.currentBrand.InterestedContact.PortraitID = newValue
      }
    },
    companyContactsWithoutLogin: function () {
      let contactsWithoutLogin = []

      this.companyContacts.forEach((contact) => {
        let isInBrandManagers = false

        this.currentMarkenverwalter.forEach((brandManager) => {
          if (brandManager.ContactID === contact.ContactID) {
            isInBrandManagers = true
          }
        })

        if (!isInBrandManagers) {
          contactsWithoutLogin.push(contact)
        }
      })

      contactsWithoutLogin = contactsWithoutLogin.concat(
        this.externalBrandContacts.slice(0)
      )

      return contactsWithoutLogin
    },
    externalBrandRoleID: function () {
      const externalBrandRole = this.preloads.brandroles
        .filter(function (brandrole) {
          return brandrole.Caption === 'Externer Partner'
        })
        .shift()
      return externalBrandRole ? externalBrandRole.BrandRoleID : false
    },
    brandManagerRoleID: function () {
      const brandManagerRole = this.preloads.brandroles
        .filter(function (brandrole) {
          return brandrole.Caption === 'Markenverwalter'
        })
        .shift()
      return brandManagerRole ? brandManagerRole.BrandRoleID : false
    },
    currentMarkenverwalter: function () {
      return (
        this.brandContacts.filter((contact) => {
          return contact.BrandRoleID === this.brandManagerRoleID
        }) || []
      )
    },
    externalBrandContacts: function () {
      const externalBrandContactsUnique = []
      this.brandContacts
        .filter((contact) => {
          return (
            contact.BrandRoleID === this.externalBrandRoleID &&
            contact.Contact.Firstname.length
          )
        })
        .forEach((contact) => {
          if (
            externalBrandContactsUnique.findIndex((value) => {
              return value.ContactID === contact.ContactID
            }) === -1
          ) {
            externalBrandContactsUnique.push(contact)
          }
        })
      return externalBrandContactsUnique
    },
    addExternalBrandManagerFormLink: function () {
      return `https://franchiseportal1.typeform.com/to/hJ7aw4?button=consultant_create&user_id=${this.app.login.user.UserID}&brand_id=${this.currentBrand.BrandID}`
    },
    removeExternalBrandManagerFormLink: function () {
      return `https://franchiseportal1.typeform.com/to/hJ7aw4?button=consultant_delete&user_id=${this.app.login.user.UserID}&brand_id=${this.currentBrand.BrandID}`
    },
    removeLoginFormLink: function () {
      return `https://franchiseportal1.typeform.com/to/hJ7aw4?button=login_delete&user_id=${this.app.login.user.UserID}&brand_id=${this.currentBrand.BrandID}`
    },
    app: function () {
      return this.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    emptyContactFormData: function () {
      return emptyContactFormData
    },
    defaultImage () {
      return require('../../assets/images/platzhalter-portrait-weiss.jpg')
    }
  },
  watch: {
    'app.hasCurrentBrand': function () {
      this.loadData()
    }
  },
  mounted: function () {
    if (this.app.hasCurrentBrand) {
      this.resetLoad()
      this.loadData()
    }
  },
  methods: {
    startLoader () {
      this.pageStore.startLoading('loadContacts')
    },
    stopLoader () {
      this.pageStore.stopLoading('loadContacts')
    },
    registerChange () {
      this.app.registerChangedContent(this.app.saveBrand)
    },
    cancelEdit (cb) {
      cb()
      this.resetForm()
    },
    resetLoad () {
      this.companyContacts = []
      this.brandContacts = []
      this.showContactAddForm = false
      this.companyLoadedID = null
    },
    changeContactRoleID (roleID, newID) {
      this.app.API._post('contactbrandroles/', {
        FullSubEntities: false,
        ResFrom: 1,
        ResTo: 100,
        Filter: [
          {
            Field: 'BrandRoleID',
            Operator: 'EQ',
            Value: roleID
          },
          {
            Field: 'BrandID',
            Operator: 'EQ',
            Value: this.currentBrand.BrandID
          }
        ]
      }).then((response) => {
        const results = response.body.Result.Data

        Promise.all(
          results.map((result) => {
            const deleteString = String(
              `contactbrandrole/${result.ContactBrandRoleID}`
            )
            return this.app.API._delete(deleteString)
          })
        ).then(() => {
          this.app.API._put('contactbrandrole', {
            ContactBrandRoleID: -1,
            ContactID: newID,
            BrandID: this.currentBrand.BrandID,
            BrandRoleID: roleID
          })
        })
      })
    },
    changePrimaryContactID () {
      this.changeContactRoleID(5, this.currentBrand.PrimaryContactID)
    },
    showCompanyNameIfAvailable (contact) {
      if (contact.CompanyID && contact.Company.Name) {
        return contact.Company.Name
      }
      return ''
    },
    addBrandManager () {
      if (this.newBrandManagerSelect > 0) {
        this.startLoader()

        const contact = this.companyContacts
          .filter((contact) => {
            return contact.ContactID === this.newBrandManagerSelect
          })
          .shift()

        let userAlreadyExists = false
        new Promise((resolve, reject) => {
          this.app.API._post('users/', {
            FullSubEntities: false,
            ResFrom: 1,
            ResTo: 100,
            Filter: [
              {
                Field: 'Email',
                Operator: 'EQ',
                Value: contact.Contact.Mail
              }
            ]
          })
            .then((response) => {
              if (response.body.Result.Data.length > 0) {
                userAlreadyExists = true
              }
              resolve()
            })
            .catch(reject)
        })
          .then(() => {
            new Promise((resolve, reject) => {
              const newContactBrandRole = {
                ContactBrandRoleID: -1,
                ContactID: contact.ContactID,
                BrandID: this.currentBrand.BrandID,
                BrandRoleID: this.brandManagerRoleID
              }

              this.app.API._put('contactbrandrole/', newContactBrandRole)
                .then((response) => {
                  const newID = response.body.Result.NewID

                  this.app.API._post('contactbrandroles/', {
                    ResFrom: 1,
                    ResTo: 1,
                    FullSubEntities: true,
                    Filter: [
                      {
                        Field: 'ContactBrandRoleID',
                        Operator: 'EQ',
                        Value: newID
                      }
                    ]
                  })
                    .then((response) => {
                      const newBrandContact = Object.assign(
                        {},
                        response.body.Result.Data[0]
                      )
                      this.brandContacts.push(newBrandContact)

                      if (userAlreadyExists === false) {
                        const newUser = {
                          Username: newBrandContact.Contact.Mail,
                          Password: generatePassword(8),
                          Email: newBrandContact.Contact.Mail,
                          SysRoleID: 3
                        }
                        this.app.API._put('user/', newUser).finally(() => {
                          resolve({
                            newBrandContact,
                            newUser
                          })
                        })
                      } else {
                        resolve({
                          newBrandContact
                        })
                      }
                    })
                    .catch(reject)
                })
                .catch(reject)
            })
              .then((newUserData) => {
                if (typeof newUserData.newUser !== 'undefined') {
                  const newUser = newUserData.newUser

                  const successHtml = this.$t(
                    'page.contacts.newbrandmanageradded',
                    { username: newUser.Username, password: newUser.Password }
                  )

                  this.$swal({
                    title: this.$t('popupmessage.success.savedtitle'),
                    icon: 'success',
                    html: successHtml,
                    showCloseButton: true,
                    showCancelButton: false
                  })
                } else {
                  this.$swal(
                    this.$t('popupmessage.success.savedtitle'),
                    this.$t('popupmessage.success.loginadded', {
                      mail: newUserData.newBrandContact.Contact.Mail
                    })
                  )
                }

                this.newBrandManagerSelect = 0
                this.stopLoader()
              })
              .catch(() => {
                this.$swal(
                  this.$t('popupmessage.error.title'),
                  this.$t('popupmessage.error.unknown'),
                  'error'
                )
                this.stopLoader()
              })
          })
          .catch(() => {
            this.$swal(
              this.$t('popupmessage.error.title'),
              this.$t('popupmessage.error.mailalreadyexists'),
              'error'
            )
            this.stopLoader()
          })
      } else {
        this.$swal(
          this.$t('popupmessage.error.title'),
          this.$t('popupmessage.error.selectcontact'),
          'error'
        )
        this.stopLoader()
      }
    },
    saveContactDetails () {
      this.app.saveBrand()
    },
    changeInterestedContact (event) {
      const newInterestedContactID = event.target.value
      this.loadContact(newInterestedContactID).then((contact) => {
        this.currentBrand.InterestedContactID = newInterestedContactID
        this.currentBrand.InterestedContact = Object.assign({}, contact)
        this.changeContactRoleID(2, newInterestedContactID)
      })
    },
    loadContact (id) {
      return new Promise((resolve, reject) => {
        this.app.API._post('contacts/', {
          ResFrom: 1,
          ResTo: 1,
          FullSubEntities: true,
          Filter: [
            {
              Field: 'ContactID',
              Operator: 'EQ',
              Value: id
            }
          ]
        })
          .then((response) => {
            resolve(response.body.Result.Data.shift())
          })
          .catch(reject)
      })
    },
    saveImageToContact (newImage) {
      this.app.API._put('contact/', {
        ContactID: this.currentBrand.InterestedContactID,
        PortraitID: newImage.MediumID
      })
    },
    openRemoveNotice (Contact) {
      const mailSubject = Contact.Contact.Mail
        ? `?subject=Kontakt ${Contact.Contact.Mail} entfernen`
        : ''
      const deleteLink = `<a href="mailto:contact@franchiseportal.de${mailSubject}">contact@franchiseportal.de</a>`
      this.$swal(
        this.$t('popupmessage.info.note'),
        this.$t('popupmessage.info.addcontactnotpossible', {
          deleteLink
        }),
        'info'
      )
    },
    editCompanyContact (companyContact) {
      this.currentContactFormData = {
        ContactCompanyRoleID: companyContact.ContactCompanyRoleID,
        ContactID: companyContact.ContactID,
        CompanyID: this.currentBrand.CompanyID,
        CompanyRoleID: companyContact.CompanyRoleID,
        SalutationID: companyContact.Contact.SalutationID,
        TitleID: companyContact.Contact.TitleIDs.length
          ? companyContact.Contact.TitleIDs[0]
          : 0,
        Firstname: companyContact.Contact.Firstname,
        Lastname: companyContact.Contact.Lastname,
        Phone: companyContact.Contact.Phone,
        Mobile: companyContact.Contact.Mobile,
        Mail: companyContact.Contact.Mail
      }
      this.contactMailBeforeSave = this.currentContactFormData.Mail
      this.$refs.contactEditModal.show()
    },
    addContact () {
      this.resetForm()
      this.$refs.contactEditModal.show()
    },
    saveCompanyContact (cb) {
      this.logoutAfterUsernameChange = false

      let error = false
      let updateContact = {}

      if (!this.currentContactFormData.SalutationID) error = true
      if (!this.currentContactFormData.Firstname) error = true
      if (!this.currentContactFormData.Lastname) error = true
      if (!this.currentContactFormData.Mail) error = true
      if (!this.currentContactFormData.CompanyRoleID) error = true

      if (error === false) {
        if (this.currentContactFormData.ContactCompanyRoleID === -1) {
          updateContact = {
            ContactID: this.currentContactFormData.ContactID,
            SalutationID: this.currentContactFormData.SalutationID,
            Firstname: this.currentContactFormData.Firstname,
            Lastname: this.currentContactFormData.Lastname,
            Phone: this.currentContactFormData.Phone,
            Mobile: this.currentContactFormData.Mobile,
            Mail: this.currentContactFormData.Mail,
            CompanyID: this.currentBrand.CompanyID
          }

          if (this.currentContactFormData.TitleID) {
            updateContact.TitleIDs = [this.currentContactFormData.TitleID]
          }

          this.app.API._put('contact/', updateContact).then((response) => {
            this.startLoader()
            const updateObject = {
              ContactCompanyRoleID:
                this.currentContactFormData.ContactCompanyRoleID,
              ContactID: response.body.Result.NewID,
              CompanyRoleID: this.currentContactFormData.CompanyRoleID,
              CompanyID: this.currentBrand.CompanyID
            }

            this.app.API._put('contactcompanyrole/', updateObject).then(() => {
              this.stopLoader()
              this.$swal(
                this.$t('popupmessage.success.savedtitle'),
                this.$t('popupmessage.success.contactadded'),
                'success'
              )
              this.loadData(true)
              this.resetForm()
              cb()
            })
          })
        } else {
          updateContact = {
            ContactID: this.currentContactFormData.ContactID,
            SalutationID: this.currentContactFormData.SalutationID,
            Firstname: this.currentContactFormData.Firstname,
            Lastname: this.currentContactFormData.Lastname,
            Phone: this.currentContactFormData.Phone,
            Mobile: this.currentContactFormData.Mobile,
            Mail: this.currentContactFormData.Mail
          }

          if (this.currentContactFormData.TitleID) {
            updateContact.TitleIDs = [this.currentContactFormData.TitleID]
          }

          const FromMail = this.contactMailBeforeSave
          const ToMail = updateContact.Mail
          this.checkIfMailChangeIsPossible(ToMail, FromMail)
            .then((result) => {
              if (result === false) {
                return false
              }

              const companyUpdateObject = {
                ContactCompanyRoleID:
                  this.currentContactFormData.ContactCompanyRoleID,
                ContactID: this.currentContactFormData.ContactID,
                CompanyRoleID: this.currentContactFormData.CompanyRoleID,
                CompanyID: this.currentBrand.CompanyID
              }
              this.startLoader()
              Promise.all([
                this.app.API._put('contact/', updateContact),
                this.app.API._put('contactcompanyrole/', companyUpdateObject)
              ]).then(() => {
                this.updateContactMail(
                  updateContact.ContactID,
                  ToMail,
                  FromMail
                ).then(() => {
                  this.stopLoader()
                  if (this.logoutAfterUsernameChange === true) {
                    this.resetLoad()
                    this.app.doLogout(true)
                    this.resetForm()
                  } else {
                    this.$swal(
                      this.$t('popupmessage.success.savedtitle'),
                      this.$t('popupmessage.success.contactadded'),
                      'success'
                    )
                    this.loadData(true)
                    this.resetForm()
                    cb()
                  }
                })
              })
            })
            .catch(() => {
              this.stopLoader()
              this.$swal(
                this.$t('popupmessage.error.title'),
                this.$t('popupmessage.error.cannotaddemail'),
                'error'
              )
            })
        }
      } else {
        this.stopLoader()
        this.$swal(
          this.$t('popupmessage.warn.attentiontitle'),
          this.$t('popupmessage.warn.requiredfields'),
          'warning'
        )
      }
    },
    resetForm () {
      this.currentContactFormData = Object.assign({}, emptyContactFormData)
      this.$refs.contactEditModal.hide()
    },
    checkIfMailChangeIsPossible (ToMail, FromMail) {
      return new Promise((resolve, reject) => {
        if (FromMail === ToMail) {
          return resolve()
        }

        if (FromMail && ToMail && FromMail !== ToMail) {
          this.secureUsernameChangePrompt(ToMail, FromMail)
            .then(() => {
              Promise.all([
                this.app.API._post('users/', {
                  FullSubEntities: false,
                  ResFrom: 1,
                  ResTo: 1,
                  filter: [
                    {
                      field: 'Email',
                      operator: 'EQ',
                      value: FromMail
                    }
                  ]
                }),
                this.app.API._post('users/', {
                  FullSubEntities: false,
                  ResFrom: 1,
                  ResTo: 1,
                  filter: [
                    {
                      field: 'Email',
                      operator: 'EQ',
                      value: ToMail
                    }
                  ]
                })
              ]).then(([ResultFromMail, ResultToMail]) => {
                const isUserExistent =
                  ResultFromMail.body.Result.Data.length === 1
                const isNewUserAvailable =
                  ResultToMail.body.Result.Data.length === 0

                if (!isUserExistent) {
                  return resolve()
                }

                if (isUserExistent && isNewUserAvailable) {
                  resolve()
                } else {
                  reject(new Error('Username kann nicht geändert werden!'))
                }
              })
            })
            .catch(() => {
              resolve(false)
            })
        } else {
          resolve()
        }
      })
    },
    secureUsernameChangePrompt (ToMail, FromMail) {
      return new Promise((resolve, reject) => {
        const CurrentLoggedInUsername = this.app.login.user.Name

        if (CurrentLoggedInUsername === FromMail) {
          this.$swal({
            title: this.$t('popupmessage.warn.attentiontitle'),
            html: this.$t('popupmessage.warn.youwillbeloggedout', {
              mail: ToMail
            }),
            showCancelButton: true,
            confirmButtonText: this.$t('popupmessage.warn.yeschangemailbtn'),
            cancelButtonText: this.$t('button.cancel'),
            icon: 'question'
          }).then((userConfirmation) => {
            if (
              typeof userConfirmation.value === 'undefined' ||
              userConfirmation.value !== true
            ) {
              return reject(new Error('Canceled Username Change!'))
            }
            this.logoutAfterUsernameChange = true
            resolve()
          })
        } else {
          resolve()
        }
      })
    },
    updateContactMail (ContactID, ToMail, FromMail) {
      return new Promise((resolve, reject) => {
        this.app.API._post('users/', {
          FullSubEntities: false,
          ResFrom: 1,
          ResTo: 1,
          filter: [
            {
              field: 'Email',
              operator: 'EQ',
              value: FromMail
            }
          ]
        }).then((ResultFromMail) => {
          const existentUser = ResultFromMail.body.Result.Data[0]
          const updateUser = {
            UserID: existentUser.UserID,
            Username: ToMail,
            Email: ToMail
          }

          if (Config.PRODUCTION === true) {
            axios.post(
              `${Config.FPSERVICE_URL}reports/mail-change`,
              {
                FromMail,
                ToMail,
                ContactID,
                UserToken: this.app.login.token,
                User: this.app.login.user
              },
              {
                json: true
              }
            )
          }

          this.app.API._put('user/', updateUser)
            .then(resolve)
            .catch(() => {
              reject(new Error('Not able to save new User data!'))
            })
        })
      })
    },
    loadData (force = false) {
      if (!this.app) return
      if (!this.app.hasCurrentBrand) return
      if (typeof this.app.API !== 'object') return
      if (typeof this.app.API._post !== 'function') return

      if (this.companyLoadedID === this.currentBrand.BrandID && !force) return

      if (this.companyLoadedID !== this.currentBrand.BrandID) {
        this.companyContacts = []
        this.companyLoadedID = null
      }

      this.startLoader()
      console.info(this.currentBrand.CompanyID)

      return Promise.all([
        this.app.API._post('contactcompanyroles/', {
          ResFrom: 1,
          ResTo: 100,
          FullSubEntities: true,
          Filter: [
            {
              Field: 'CompanyID',
              Operator: 'eq',
              Value: this.currentBrand.CompanyID
            }
          ]
        }).then((response) => {
          this.companyContacts = response.body.Result.Data.filter((contact) => {
            return contact.Contact.Active === 1
          })
        }),
        this.app.API._post('contactbrandroles/', {
          ResFrom: 1,
          ResTo: 100,
          FullSubEntities: true,
          Filter: [
            {
              Field: 'BrandID',
              Operator: 'eq',
              Value: this.currentBrand.BrandID
            }
          ]
        }).then((response) => {
          this.brandContacts = response.body.Result.Data.filter((contact) => {
            return contact.Contact.Active === 1
          })
        })
      ]).then(() => {
        this.stopLoader()
        this.companyLoadedID = this.currentBrand.BrandID
        this.allContacts = [...this.companyContacts, ...this.brandContacts]

        for (let i = 0; i < this.allContacts.length; i++) {
          if (this.allContacts[i].BrandRoleID === 5) {
            this.ContactsFP.primaerkontakt.list.push(
              Object.assign({}, this.allContacts[i])
            )
          }
          if (this.allContacts[i].BrandRoleID === 2) {
            this.ContactsFP.interessenten.list.push(
              Object.assign({}, this.allContacts[i])
            )
          }
        }

        this.companyContacts.forEach((companyContact) => {
          const contactIntExists = this.ContactsFP.interessenten.list.find(
            (contact) => {
              return contact.ContactID === companyContact.ContactID
            }
          )
          if (contactIntExists == null) {
            this.ContactsFP.interessenten.list.push(
              Object.assign({}, companyContact)
            )
          }
          const contactPrimExists = this.ContactsFP.primaerkontakt.list.find(
            (contact) => {
              return contact.ContactID === companyContact.ContactID
            }
          )
          if (contactPrimExists == null) {
            this.ContactsFP.primaerkontakt.list.push(
              Object.assign({}, companyContact)
            )
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.markenverwalterListe li {
  margin-bottom: 1em;
}
.mb-main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mb-kontakte {
  h2 {
    display: flex;
    margin-bottom: 20px;

    span {
      border-bottom: 3px dashed #000;
      display: block;
      position: relative;
      width: 88px;
      margin-left: 15px;
      height: 25px;

      &::after {
        position: absolute;
        right: -25px;
        top: 3px;
        font-size: 40px;
      }
    }
  }

  .mb-contact-data {
    label {
      margin-bottom: 0px;
      display: block;
    }
  }

  .btn {
    text-transform: none;
  }

  .card-title {
    margin-bottom: 10px;
    text-transform: none;
  }

  .mb-expert {
    height: 92px;
  }
}
.btn.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
</style>
