<template>
  <div>
    <div
      v-if="app.hasMessestand"
      id="bilder"
      class="container mb-marke-bilder p-0 mt-5"
    >
      <h4 class="mb-card-title">
        {{ $t('page.profile.picture.title') }}
      </h4>
      <div class="card mb-5">
        <div class="card-body">
          <form>
            <h5 class="card-title">
              {{ $t('page.profile.picture.mainpicture') }}
            </h5>
            <div class="row">
              <div class="col-sm position-relative">
                <div
                  v-if="!(messestand.MainPictureID>0)"
                  class="mb-tile-list"
                >
                  <div class="card mb-tile">
                    <div class="mb-new">
                      <a
                        href="javascript:"
                        class="card-link"
                        @click.prevent="addMainImage"
                      >
                        <span>+</span>
                        {{ $t('page.profile.picture.addmainpicture') }}
                      </a>
                    </div>
                  </div>
                </div>
                <image-connected-selector
                  v-show="messestand.MainPictureID>0"
                  ref="mainImageSelector"
                  v-model="messestand.MainPictureID"
                  class="mb-edit-img"
                  :ratio="509/218"
                  :resolution-hint="$t('image.recomainpicture')"
                  img-type="mainpicture"
                  @change="registerChange"
                />
              </div>
              <copyright-input
                v-if="messestand.MainPictureID>0&&messestand.MainPicture&&typeof messestand.MainPicture.Copyright !== 'undefined'"
                v-model="messestand.MainPicture.Copyright"
                :image="messestand.MainPicture"
                :name="`MainPictureID_${messestand.FairID}`"
              />
            </div>
            <h5 class="card-title mt-5">
              {{ $t('page.profile.picture.morepictures', { count: fairImages.length }) }}
            </h5>
            <div class="mb-tile-list fairPicturesList" style="overflow-x: auto">
              <Container @drop="onDrop" drag-handle-selector=".column-drag-handle" v-if="fairImages.length > 0" orientation="horizontal" behaviour="contain">
                  <Draggable v-for="(image, index) in messestand.FairPictures" :key="index" class="card mb-tile">
                      <div class="draggable-item-horizontal">
                          <div class="mb-edit-img">
                            <div class="column-drag-handle" style="padding:0 10px;">&#x2630; Verschieben</div>
                            <image-connected-selector
                              v-if="image.Medium"
                              :ref="`fairImageSelector_${index}`"
                              v-model="image.MediumID"
                              class="mb-edit-img"
                              @change="saveFairPictures"
                            />
                            <span
                              class="icon-trash"
                              @click.prevent="deleteImage(image)"
                            />
                          </div>
                          <div class="card-body">
                            <copyright-input
                              v-model="image.Medium.Copyright"
                              :image="image.Medium"
                              :name="`PictureID_${index}`"
                              @change="saveFairPictures"
                            />
                          </div>
                      </div>
                  </Draggable>
              </Container>
            </div>
            <a
              href="javascript:"
              class="btn btn-primary"
              @click.prevent="addImage"
            >
              <span>+</span>
              {{ $t('page.profile.picture.addnew') }}
            </a>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageConnectedSelector from '../../../components/ImageConnectedSelector'
import CopyrightInput from '../../../components/CopyrightInput'
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from '../../../helpers/dndhelper'

export default {
  name: 'BrandProfileImages',
  components: {
    ImageConnectedSelector,
    CopyrightInput,
    Container,
    Draggable
  },
  props: [],
  data: function () {
    return {
      isDropAction: false
    }
  },
  computed: {
    emptyFairPicture: function () {
      return {
        FairPictureID: -1,
        FairID: this.messestand.FairID,
        MediumID: null,
        PictureSource: null,
        Medium: {
          MediumID: 0
        },
        SortPos: 0
      }
    },
    fairImages: function () {
      return this.messestand.FairPictures
    },
    app: function () {
      return this.$parent.$parent || {}
    },
    currentBrand: function () {
      return this.$parent.currentBrand || {}
    },
    preloads: function () {
      return this.$parent.preloads || {}
    },
    messestand: function () {
      return this.app.messestand || {}
    }
  },
  watch: {
    'messestand.MainPictureID': function (newMainPictureID) {
      if (newMainPictureID > 0) {
        this.app.API._post('medium', {
          MediumID: newMainPictureID
        }).then(response => {
          this.messestand.MainPicture = response.body.Result.Data
        })
      }
    }
  },
  methods: {
    registerChange () {
      this.app.registerChangedContent(this.saveFairPictures)
    },
    deleteImageFromApi (imageToDelete) {
      this.app.API._delete(
        ['fairpicture', '/', imageToDelete.FairPictureID].join('')
      ).then(() => {
        this.messestand.FairPictures = this.messestand.FairPictures.filter(
          image => {
            return imageToDelete.FairPictureID !== image.FairPictureID
          }
        )
      })
    },
    deleteImage (imageToDelete) {
      if (imageToDelete.FairPictureID === -1) {
        this.messestand.FairPictures = this.messestand.FairPictures.filter(
          image => {
            return imageToDelete.MediumID !== image.MediumID
          }
        )

        return false
      }

      this.$swal({
        title: this.$t('popupmessage.warn.areyousuretitle'),
        text: this.$t('popupmessage.warn.imagedeletemessage'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then(result => {
        if (typeof result.value !== 'undefined') {
          this.deleteImageFromApi(imageToDelete)
        }
      })
    },
    addImage () {
      if (
        this.messestand.FairPictures.length < 1000
      ) {
        this.messestand.FairPictures.unshift(
          Object.assign({}, this.emptyFairPicture)
        )
        setTimeout(() => {
          const dynamicRef = `fairImageSelector_${0}`
          this.$refs[dynamicRef][0].openChangeModal()
        }, 250)
      } else {
        this.$swal(this.$t('popupmessage.warn.attentiontitle'), this.$t('popupmessage.warn.maximagesreached'), 'warning')
      }
    },
    addMainImage () {
      this.$refs.mainImageSelector.openChangeModal()
    },
    saveFairPictures () {
      const loader = this.app.startLoader()

      new Promise((resolve, reject) => {
        const dataToSave = {
          FairID: this.messestand.FairID,
          MainPictureID: this.messestand.MainPictureID
        }

        if (
          dataToSave.MainPictureID === 0 ||
          dataToSave.MainPictureID === null
        ) {
          dataToSave.NullFields = ['MainPictureID']
        }

        this.app.API._put('fair/', dataToSave)
          .then(resolve)
          .catch(reject)
      }).then(() => {
        Promise.all(
          this.messestand.FairPictures.filter(fairpicture => {
            return !!fairpicture.MediumID
          }).map(fairpicture => {
            return new Promise((resolve, reject) => {
              const objectToSave = Object.assign({}, fairpicture)

              delete objectToSave.Medium

              this.app.API._put('fairpicture/', objectToSave)
                .then(response => {
                  if (response.body.Result.NewID
                  ) {
                    fairpicture.FairPictureID = response.body.Result.NewID
                  }
                  this.app.unsavedChanges = []
                  resolve()
                })
                .catch(reject)
            })
          })
        )
          .then(
            this.$toast.success(this.$t('popupmessage.success.imagessaved'))
          )
          .catch(() => {
            this.$swal(this.$t('popupmessage.error.title'), this.$t('popupmessage.error.unknown'), 'error')
          })
          .finally(loader.hide)
      })
    },
    onDrop (dropResult) {
      this.isDropAction = true
      this.messestand.FairPictures = applyDrag(this.messestand.FairPictures, dropResult)
      this.handleSortOrderChange()
      setTimeout(function () { this.isDropAction = false }, 1000)
      this.$toast.success(this.$t('component.changedpossaved'))
    },
    handleSortOrderChange () {
      for (let i = 0; i < this.messestand.FairPictures.length; i++) {
        const elm = this.messestand.FairPictures[i]
        this.app.API._put('fairpicture', {
          FairPictureID: elm.FairPictureID,
          SortPos: i
        })
      }
    }

  }
}
</script>

<style lang="less">
  .smooth-dnd-container.horizontal {
    border-spacing: 20px 0px;
  }
  .animated.smooth-dnd-draggable-wrapper {
    .column-drag-handle {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #ccc;
    }
  }
  .column-drag-handle {
    cursor: move;
    color: #999;
    font-size: 14px;

    &:hover {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #ccc;
    }
  }
  .draggable-item-horizontal {
    min-width: 250px;
  }
</style>
